import { useEffect } from 'react';

import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { FormCategory, Modal } from '@organisms';
import { getOneCategory, QUERY_KEYS, updateCategory } from '@api';

import type { IModalEditCategoryProps, IFormEditCategoryValues } from './ModalEditCategory.interfaces';

export const ModalEditCategory = ({ isVisible, onCloseModal, editingItemId }: IModalEditCategoryProps) => {
	const form = useForm<IFormEditCategoryValues>();
	const {
		handleSubmit: handleSubmitWrapper,
		reset,
		setError,
		formState: { isDirty },
	} = form;

	useEffect(() => {
		isVisible && reset();
	}, [isVisible]);

	const { isLoading, isError, data } = useQuery({
		queryKey: [QUERY_KEYS.categories, editingItemId],
		queryFn: () => getOneCategory({ id: editingItemId }),
	});

	useEffect(() => {
		if (isLoading) {
			reset({
				name: 'Loading...',
				description: 'Loading...',
			});
		}

		if (!isLoading && !isError) {
			reset({
				name: data?.name,
				description: data?.description,
			});
		}
	}, [isLoading, isError, data]);

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationKey: ['categories'],
		mutationFn: updateCategory,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({ queryKey: ['categories'] });
		},
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				setError('name', { message: 'Categoría ya existe' }, { shouldFocus: true });
			} else {
				throw error;
			}
		},
	});
	const handleSubmit = ({ name, description }: IFormEditCategoryValues) => {
		mutation.mutate({
			id: editingItemId,
			name,
			description,
		});
	};

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Edición de categoría'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-3/4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
				dolore magna aliqua.
			</p>
			<FormProvider {...form}>
				<FormCategory
					canSave={isDirty}
					isLoading={mutation.isPending}
					onSubmit={handleSubmitWrapper(handleSubmit)}
					onCancel={onCloseModal}
				/>
			</FormProvider>
		</Modal>
	);
};
