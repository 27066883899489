import { FIELD_TYPES } from '@utils';
import { Field, IDraggableFormState } from './interfaces';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import {
	FormDateField,
	FormInformativeTextField,
	FormKitSkanField,
	FormListField,
	FormNumberField,
	FormPhotoField,
	FormQrScanField,
	FormTextField,
	FormTextScanField,
	FormTimeField,
	FormTitleField,
} from '@organisms';
import { Button, DashedContainer, IcAdd } from '@atoms';
import { DeleteButton, DraggableItem, DraggableList, FormInput, FormSearchableDropdown, MoveButton } from '@molecules';
import { useDraggableForm } from './useDraggableForm';

interface IInnerFormProps {
	onClose: () => void;
	initialFormTitle?: string;
	initialState?: IDraggableFormState;
	initialTitle?: string;
	onSave: (
		formData: {
			title: string;
			items: {
				fieldId?: string;
				id: string; // this is the component id
				isRequired: boolean;
				defaultValue: string;
				label: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) => void;
	canSaveFn?: (params: {
		title: {
			value: string;
			isDirty: boolean;
		};
		fields: {
			value: Field[];
			isDirty: boolean;
		};
	}) => boolean;
	isLoading: boolean;
}

// Creo que para que esto sea más predecible, cada vez que se cambie el tipo de campo, se debe reiniciar el estado de ese campo guardado si lo hubiere
export function InnerForm({
	isLoading,
	initialFormTitle,
	canSaveFn = () => true,
	initialState,
	onSave,
}: IInnerFormProps) {
	const form = useForm<{ formTitle: string }>({
		defaultValues: { formTitle: initialFormTitle },
	});

	const { title, fields, editingField, areFieldsDirty, handlers, fieldsContainerElementRef } = useDraggableForm({
		initialState,
	});
	const {
		handleAddItem,
		handleUndo,
		handleChangeFieldType,
		handleDeleteField,
		handleEnableItemEdition,
		handleMoveDown,
		handleMoveUp,
		handleSaveItem,
		handleSetItems,
		handleReset,
		handleChangeTitle,
	} = handlers;

	function handleSaveForm(formValues: { formTitle: string }) {
		console.log('submitted with', formValues);
		onSave(
			{
				title: formValues.formTitle,
				items: fields.map(({ data: field, backendId }, index) => ({
					fieldId: backendId,
					id: field.id,
					isRequired: field.isRequired,
					defaultValue: field.defaultValue || '',
					label: field.label,
					order: index.toString(),
				})),
			},
			handleReset
		);
	}

	function renderForm(fieldType: (typeof FIELD_TYPES)[number]) {
		switch (fieldType) {
			case 'Texto':
				return (
					<FormTextField
						title={title}
						defaultValues={{ ...editingField.data, title }}
						onSave={formValues => {
							console.log('saving with', {
								...formValues,
								title,
							});
							handleSaveItem({
								title,
								formValues,
							});
						}}
						onUndo={handleUndo}
					/>
				);
			case 'Número':
				return (
					<FormNumberField
						title={title}
						defaultValues={{ ...editingField.data, title }}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Lista desplegable':
			case 'Lista desplegable selección múltiple':
				return (
					<FormListField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Fecha y hora':
				return (
					<FormDateField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Hora':
				return (
					<FormTimeField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Título':
				return (
					<FormTitleField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Texto informativo / instrucción':
				return (
					<FormInformativeTextField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Separador':
				return (
					<div className='flex-grow flex items-end'>
						<div className='flex gap-4'>
							<Button
								disabled={editingField.data.label === 'Separador'}
								// Must be disabled after saving
								onClick={() =>
									handleSaveItem({
										title: 'Separador',
										formValues: {
											id: 'd12c56c8-df55-4560-b060-dffda4baf904',
											label: 'Separador',
											defaultValue: '',
											isRequired: false,
										},
									})
								}
							>
								Guardar Cambios
							</Button>
							{/*
							<Button
								onClick={() => handleUndo('Separador')}
								type='button'
								className='w-[8.125rem]'
								variant='secondary'
							>
								Cancelar
							</Button>
                            */}
						</div>
					</div>
				);
			case 'Foto':
				return (
					<FormPhotoField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Escáner de kit':
				return (
					<FormKitSkanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Escáner de texto':
				return (
					<FormTextScanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			case 'Escáner de QR':
				return <div>Add this component</div>;
			case 'Entidades':
				return (
					<FormQrScanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
			default:
				// Esto es útil para el "Código de barra del formato"
				return (
					<FormQrScanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							handleSaveItem({
								title,
								formValues,
							})
						}
						onUndo={handleUndo}
					/>
				);
		}
	}

	const canSave = canSaveFn({
		title: {
			value: '',
			isDirty: form.formState.isDirty,
		},
		fields: {
			value: fields,
			isDirty: areFieldsDirty,
		},
	});
	console.log(fields);

	return (
		<Fragment>
			<p className='text-xs'>
				Personaliza un nuevo formulario definiendo los campos, valores predeterminados y opciones de
				visualización. Todos los cambios se pueden modificar más adelante.
			</p>

			<FormInput
				required
				errorMessage={form.formState.errors.formTitle?.message}
				onError={!!form.formState.errors.formTitle}
				register={form.register('formTitle', {
					required: { message: 'Ingresa un título', value: true },
				})}
				label='Título'
				placeholder='Título de Formulario'
			/>

			<hr />

			<div className='flex gap-4'>
				<div className='basis-0 grow'>
					<h2 className='text-xs font-semibold text-bummock-midnight_blue mb-2'>Campos del formulario</h2>
					<div className='flex flex-col gap-4 h-[465px]'>
						<div
							ref={fieldsContainerElementRef}
							className='flex flex-col gap-4 overflow-x-hidden overflow-scroll pr-4'
						>
							<DraggableList items={fields} setItems={handleSetItems}>
								{fields.map((item, index) => (
									<DraggableItem
										key={item.id}
										id={item.id}
										label={
											editingField.id === item.id && item.data.label !== 'Separador'
												? title
												: item.data.label
										}
										isBeingEdited={item.isBeingEdited}
										onEdit={() => handleEnableItemEdition(item.id)}
									>
										{({ isBeingEdited, label, isDragging }) => (
											<>
												{label === 'Separador' ? (
													<div
														className={`h-[1px] flex-grow ${isBeingEdited ? 'bg-white' : 'bg-black'}`}
													></div>
												) : (
													<span
														className={`select-none cursor-text basis-[17.75rem] grow-[2] text-sm shrink-0 h-9 rounded-lg flex items-center p-4 ${!isBeingEdited ? 'bg-bummock-off_white shadow-bummock' : ''} ${isBeingEdited ? 'text-white' : label ? 'text-black' : 'text-bummock-disabled_grey_text'}`}
													>
														{label || 'Ejemplo: Nombre del cliente'}
													</span>
												)}
												<MoveButton
													variant={isBeingEdited ? 'dark' : 'light'}
													disabled={index === 0}
													onClick={() => handleMoveUp(index)}
													isBeingEdited={isBeingEdited}
													direction='up'
												/>
												<MoveButton
													variant={isBeingEdited ? 'dark' : 'light'}
													disabled={index >= fields.length - 1}
													onClick={() => handleMoveDown(index)}
													isBeingEdited={isBeingEdited}
													direction='down'
												/>
												<DeleteButton
													variant={isBeingEdited ? 'dark' : 'light'}
													isBeingEdited={isBeingEdited}
													isDragging={isDragging}
													onClick={() => handleDeleteField(item.id)}
													disabled={fields.length === 1}
												/>
											</>
										)}
									</DraggableItem>
								))}
							</DraggableList>
						</div>
						<DashedContainer
							onClick={handleAddItem}
							className='cursor-pointer flex shrink-0 gap-2 text-sm items-center'
						>
							<IcAdd />
							<span className='font-semibold text-bummock-midnight_blue text-xs'>Agregar otro campo</span>
						</DashedContainer>
					</div>
				</div>
				<div className='w-[1px] bg-bummock-disabled_grey'></div>
				<div className='basis-0 grow flex flex-col'>
					<div className='flex flex-col gap-6 grow'>
						{/* Esto va fijo */}
						{/* When this happen the type of the current field should update */}
						<FormSearchableDropdown
							label='Tipo de campo'
							options={[
								{
									id: '',
									value: 'Selecciona un tipo de campo',
								},
								...FIELD_TYPES.map(fieldType => ({
									id: fieldType,
									value: fieldType,
								})),
							]}
							value={editingField.type}
							onChange={(newType: unknown) =>
								handleChangeFieldType(newType as (typeof FIELD_TYPES)[number])
							}
						/>
						{/* This is dynamic based on the selected field type */}
						{editingField.type ? (
							<Fragment key={editingField.id}>
								{editingField.type !== 'Separador' && (
									<FormInput
										label='Título'
										placeholder='Título del campo'
										required
										value={title}
										onChange={e => handleChangeTitle(e!.target.value)}
									/>
								)}

								{renderForm(editingField.type as unknown as (typeof FIELD_TYPES)[number])}
							</Fragment>
						) : null}
					</div>
				</div>
			</div>
			<Button
				disabled={!canSave}
				isLoading={isLoading}
				onClick={form.handleSubmit(handleSaveForm)}
				className='text-sm ml-auto h-10'
			>
				Guardar Formulario
			</Button>
		</Fragment>
	);
}
