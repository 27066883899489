import { ITabRoute } from '@interfaces';
import { DriversPage, EditShippingCompanyPage, ShippingCompaniesPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';
import { TabbedLayout } from '@layout';

const entitiesSubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'shipping-companies',
		tabName: 'Navieras',
		requiredPermission: 'ENTITIES',
		element: <ShippingCompaniesPage />,
	}),
	createProtectedTabRoute({
		path: 'exporting-companies',
		tabName: 'Exportadoras',
		requiredPermission: 'ENTITIES',
		element: <DriversPage />,
	}),
	createProtectedTabRoute({
		path: 'service-providers',
		tabName: 'Proveedores de Servicios',
		requiredPermission: 'ENTITIES',
		element: <DriversPage />,
	}),
];

export const entitiesRoutes: RouteObject[] = [
	{
		path: 'entities',
		element: <TabbedLayout tabRoutes={entitiesSubRoutes} />,
		children: entitiesSubRoutes,
	},
	{
		path: 'entities/:id/edit',
		element: <EditShippingCompanyPage />,
	},
];
