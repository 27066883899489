import { apiPrivate } from '@api';

interface IFindLocationsByCompanyParams {
	companyId: string;
}

interface IFindLocationsByCompanyResp {
	data: ILocationByCompany[];
}

interface ILocationByCompany {
	id: string;
	name: string;
}

// Second endpoint (FUCKKKK)
export const findLocationsByCompany = async ({
	companyId,
}: IFindLocationsByCompanyParams): Promise<ILocationByCompany[]> => {
	const { data } = await apiPrivate.get<IFindLocationsByCompanyResp>(`/api/locations/${companyId}/company`);
	return data.data;
};
