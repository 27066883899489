import { Button } from '@atoms';
import { useFormModalContext } from '../../Modal/Forms/ctx';
import { FormEvent, FormEventHandler, ReactNode, useEffect, useRef } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

interface IFormWrapperProps<T extends FieldValues> {
	children: ReactNode;
	onSubmit: FormEventHandler<HTMLFormElement>;
	onUndo: (oldTitle: string) => void;
	form: UseFormReturn<T>;
	title: string;
}

// TODO: Place this component somewhere else
export function FormWrapper<T extends FieldValues>({ children, onSubmit, onUndo, form, title }: IFormWrapperProps<T>) {
	const { setCanSubmit, showError } = useFormModalContext();
	const initialTitleRef = useRef(title);
	const { isValid: isFormValid, isDirty: isFormDirty } = form.formState;
	const isDirty = title !== initialTitleRef.current || isFormDirty;
	const isValid = !!title && isFormValid;
	const canSubmit = isValid && isDirty;
	useEffect(() => setCanSubmit(canSubmit), [canSubmit]);

	function handleSubmit(e: FormEvent<HTMLFormElement>) {
		initialTitleRef.current = title;
		onSubmit(e);
	}

	function handleUndo() {
		form.reset();
		onUndo(initialTitleRef.current);
	}

	return (
		<form autoComplete='off' onSubmit={handleSubmit} className='flex flex-col justify-between flex-grow'>
			<div className='flex flex-col gap-6'>
				{children}
				<div
					className={`${showError && canSubmit ? 'opacity-100' : 'opacity-0'} duration-500 ease-out transition-opacity self-start rounded-md bg-[#FECDCA] border border-[#FDA29B] p-2 text-xs text-bummock-dark_grey`}
				>
					Los cambios no han sido guardados.
				</div>
			</div>
			<div className='flex gap-4'>
				<Button className='text-sm h-10' disabled={!canSubmit} type='submit'>
					Guardar Cambios
				</Button>
				<Button
					disabled={!isDirty}
					onClick={handleUndo}
					type='button'
					className='text-sm h-10 w-[8.125rem]'
					variant='secondary'
				>
					Deshacer
				</Button>
			</div>
		</form>
	);
}
