// import { IEntity } from '@interfaces';
import { getPaginatedResource, IPaginatedResourceParams } from '@api';

interface IEntityResponseEntity {
	id: string;
	name: string;
	country: string;
	city: string;
	category: string;
	creationDate: string;
	status: string;
}

export const findAllEntities = async (params: IPaginatedResourceParams) => {
	const { data } = await getPaginatedResource<IEntityResponseEntity>('/api/entities', params);

	const entities: IEntityResponseEntity[] = [
		{
			id: '1',
			name: 'Mearsk',
			country: 'Ecuador',
			city: 'Guayaquil',
			category: 'Naviera',
			creationDate: '02-Feb-2024',
			status: 'Activo',
		},
	];

	return {
		...data,
		data: entities,
	};
};
