import { apiPrivate } from '@api';

interface IEditProcessReqBody {
	title: string;
	referenceTitle: string;
	referenceType: string;
	workflowGroup: {
		id?: string;
		workflowHeader: string;
	}[];
	processLocations: {
		companyLocation: string;
	}[];
}

interface IEditProcessParams {
	processId: string;
	body: IEditProcessReqBody;
}

export const editProcess = async ({ processId, body }: IEditProcessParams) => {
	const { data } = await apiPrivate.patch(`/api/workflow/process/${processId}`, body);
	return data;
};
