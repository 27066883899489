import type { FC } from 'react';

import { CSS } from '@dnd-kit/utilities';
import { useDraggable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';

import { IcChevronDown, IcDrag, IcTrash } from '@atoms';

import type { IDraggableItem } from '@interfaces';

export const MoveButton = ({
	isBeingEdited,
	disabled,
	onClick,
	direction = 'up',
	variant = 'light',
}: {
	isBeingEdited: boolean;
	disabled?: boolean;
	onClick: VoidFunction;
	direction?: 'up' | 'down';
	variant?: 'light' | 'dark';
}) => {
	const editingStroke = variant === 'light' ? 'stroke-bummock-midnight_blue' : 'stroke-white';
	const baseStroke = variant === 'light' ? 'stroke-bummock-midnight_blue' : 'stroke-bummock-midnight_blue';
	const disabledStroke =
		variant === 'light' ? 'disabled:stroke-bummock-disabled_grey_2' : 'disabled:stroke-bummock-disabled_grey_2';
	const hoverStroke = variant === 'light' ? 'enabled:hover:stroke-slate-500' : 'enabled:hover:stroke-slate-500';

	return (
		<button
			disabled={disabled}
			onClick={e => {
				e.stopPropagation();
				onClick();
			}}
			className={`w-6 h-6 flex justify-center items-center ${isBeingEdited ? editingStroke : baseStroke} ${disabledStroke} ${hoverStroke}`}
		>
			<IcChevronDown
				className={`transition-colors ${direction === 'up' ? 'rotate-180' : ''} w-[0.8rem] stroke-2 stroke-inherit`}
			/>
		</button>
	);
};

export const DeleteButton = ({
	disabled,
	onClick,
	isBeingEdited,
	isDragging,
	variant = 'light',
}: {
	disabled?: boolean;
	onClick: VoidFunction;
	isBeingEdited: boolean;
	isDragging: boolean;
	variant?: 'light' | 'dark';
}) => {
	const editingStroke = variant === 'light' ? 'stroke-bummock-midnight_blue' : 'stroke-white';
	const baseStroke = variant === 'light' ? 'stroke-bummock-midnight_blue' : 'stroke-bummock-disabled_grey_2';
	const disabledStroke =
		variant === 'light' ? 'disabled:stroke-bummock-disabled_grey_2' : 'disabled:stroke-bummock-disabled_grey_2';
	const hoverStroke = variant === 'light' ? 'enabled:hover:stroke-red-500' : 'enabled:hover:stroke-red-500';

	return (
		<button
			disabled={disabled}
			onClick={e => {
				e.stopPropagation();
				onClick();
			}}
			className={`${isBeingEdited || isDragging ? editingStroke : baseStroke} disabled:cursor-not-allowed ${disabledStroke} ${hoverStroke}`}
		>
			<IcTrash className={`w-[1.3rem] stroke-[1.7] transition-colors stroke-inherit`} />
		</button>
	);
};

export const DraggableItem: FC<IDraggableItem> = ({
	id,
	label,
	onEdit,
	isBeingEdited = false,
	children,
	className = '',
}) => {
	const { isDragging } = useDraggable({ id });
	const { attributes, index, listeners, setNodeRef, transform, transition } = useSortable({ id });
	console.log(id, label);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			// duration-300 transition-colors
			className={`flex items-center gap-2 p-2 border-bummock-disabled_grey border rounded-lg ${isDragging ? 'bg-bummock-arctic_blue z-50' : isBeingEdited ? 'bg-bummock-ocean_blue' : 'bg-bummock-off_white'} ${className}`}
			ref={setNodeRef}
			style={style}
			{...attributes}
			onClick={onEdit}
		>
			<div className='w-6 h-6 cursor-grab flex justify-center items-center' {...listeners}>
				<IcDrag className={`shrink-0 cursor-grab ${isDragging ? 'stroke-white' : ''}`} />
			</div>
			<span className='basis-[2.5rem] text-sm font-bold shrink-0 h-9 rounded-lg flex justify-center items-center bg-bummock-off_white shadow-bummock'>
				{index + 1}.
			</span>
			{children({ isBeingEdited, label, isDragging })}
		</div>
	);
};
