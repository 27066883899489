import { FormInput, FormSearchableDropdown } from '@molecules';
import { Modal } from '../Modal';
import { IModalProps } from '@interfaces';
import { Alert, Button } from '@atoms';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDebouncedState, useDetectClickOutside } from '@hooks';

// Cuando se termina de escribir, se realiza la llamada que empieza a buscar a la entidad
// Si existe más de una, se muestra la lista
// Si no existe ninguna, se muestra el alert.

const fetchCompany = (searchTerm: string): Promise<string[]> =>
	new Promise(resolve =>
		setTimeout(() => resolve(searchTerm.length > 3 ? [] : ['Entity 1', 'Entity 2', 'Entity 3']), 500)
	);

interface ICompanySearchSelectProps {
	onChangeResultLength: (length: number) => void;
	// This type will most likely change
	onChangeSelectedValue: (value: string) => void;
}

const CompanySearchSelect = ({ onChangeResultLength, onChangeSelectedValue }: ICompanySearchSelectProps) => {
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
	const [searchTerm, setSearchTerm] = useDebouncedState<string>('', setDebouncedSearchTerm, 250);
	const { elementRef } = useDetectClickOutside<HTMLInputElement>({
		isOpen: true,
		onClickOutside: () => setIsVisible(false),
	});
	const { data, isLoading } = useQuery({
		queryFn: () => fetchCompany(debouncedSearchTerm),
		queryKey: ['entities-search', debouncedSearchTerm],
		enabled: !!debouncedSearchTerm,
	});
	const [isVisible, setIsVisible] = useState(true);
	// This type will prolly change
	const [selectedItem, setSelectedItem] = useState('');
	useEffect(() => {
		setIsVisible(true);
	}, [searchTerm]);

	const isActuallyLoading = isLoading || debouncedSearchTerm !== searchTerm;

	const handleClickOption = (option: string) => {
		setSearchTerm(option);
		setSelectedItem(option);
		// Add a callback
		onChangeSelectedValue(option);
	};

	useEffect(() => {
		const SOME_TRUTHY_LENGTH = 1;
		if (!searchTerm) {
			onChangeResultLength(SOME_TRUTHY_LENGTH);
			return;
		}
		if (isActuallyLoading) return;
		if (selectedItem === searchTerm) return;
		onChangeResultLength(data?.length ?? SOME_TRUTHY_LENGTH);
	}, [data, searchTerm, isActuallyLoading, selectedItem]);

	useEffect(() => {
		if (selectedItem !== searchTerm) onChangeSelectedValue('');
	}, [searchTerm, selectedItem]);

	return (
		<div className='relative'>
			<div className='flex flex-col items-start gap-3'>
				<label
					className='font-semibold text-2xs leading-none  text-bummock-midnight_blue'
					htmlFor='company-search'
				>
					Buscar RUC/Razon Social
				</label>
				<input
					autoComplete='off'
					id='company-search'
					ref={elementRef}
					value={searchTerm}
					onChange={e => setSearchTerm(e!.target.value)}
					onClick={() => !!searchTerm && setIsVisible(true)}
					placeholder='-'
				/>
			</div>
			{!!searchTerm && isVisible && (
				<ul className='bg-bummock-off_white shadow-bummock_out rounded-lg flex flex-col text-sm absolute left-0 right-0 z-[100] overflow-hidden'>
					{isActuallyLoading && <li className='px-4 py-1'>Loading...</li>}
					{!isActuallyLoading && !!data && (
						<>
							{data.map(item => (
								<li
									onClick={() => handleClickOption(item)}
									className='hover:bg-bummock-arctic_blue px-4 py-1'
									key={item}
								>
									{item}
								</li>
							))}
						</>
					)}
				</ul>
			)}
		</div>
	);
};

interface IModalNewEntityProps extends IModalProps {
	onClickInvite: VoidFunction;
}

export const ModalNewEntity = ({ isVisible, onCloseModal, onClickInvite }: IModalNewEntityProps) => {
	const [isAlertVisible, setIsAlertVisible] = useState(false);
	// Only render this when no result is retreived by using RUC or company name
	const renderAlertMessage = () => (
		<>
			<p className='text-2xs'>
				El RUC o Razon Social ingresado no pertenece a niguna entidad registrada en la plataforma de Bummock.
				Desea invitarlos a unirse a la experiencia de Bummock?{' '}
				<strong>
					<span
						onClick={onClickInvite}
						className='underline cursor-pointer active:text-gray-600 transition-colors'
					>
						Invitar
					</span>
				</strong>
			</p>
		</>
	);

	const [selectedValue, setSelectedValue] = useState('');
	const hasSelectedEntity = !!selectedValue;

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Agregar Entidad'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<div className='flex flex-col gap-4'>
				<p className='text-2xs w-3/4'>
					Start with the companies ID number in order to see if they are already using Bummock. If not then
					proceed to complete the required fields.
				</p>

				<CompanySearchSelect
					onChangeResultLength={length => setIsAlertVisible(!length)}
					onChangeSelectedValue={value => setSelectedValue(value)}
				/>

				<div className='flex gap-4'>
					<FormInput
						disabled={!hasSelectedEntity}
						placeholder='-'
						className='basis-0 grow'
						label='Categoría'
					/>
					<FormSearchableDropdown
						disabled={!hasSelectedEntity}
						className='basis-0 grow'
						label='Compartir Localidades'
						options={[]}
						value=''
					/>
				</div>

				<hr />

				<p className='text-2xs text-bummock-disabled_grey_2'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
					et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat.
				</p>

				{isAlertVisible && <Alert message={renderAlertMessage()} />}

				<Button className='self-end'>Invitar Entidad</Button>
			</div>
		</Modal>
	);
};
