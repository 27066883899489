import { apiPrivate } from '../config/apiPrivate';

interface IEditFormParams {
	formId: string;
	formData: {
		name: string;
		items: {
			id?: string;
			label: string;
			isRequired: boolean;
			defaultValue: string;
			isActive: boolean;
			component: string;
		}[];
	};
}

export const editForm = async ({ formId, formData }: IEditFormParams) => {
	const { data } = await apiPrivate.patch(`/api/workflow/forms/${formId}`, formData);
	return data;
};
