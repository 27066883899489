import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal } from '../Modal';
import { createList } from '@api';
import toast from 'react-hot-toast';
import styles from '../Forms/overrides.module.sass';
import { FormModalProvider } from '../Forms/ctx';
import { InnerForm } from './InnerForm';

interface IModalNewList {
	isModalVisible: boolean;
	onClose: VoidFunction;
}

export const ModalNewList = ({ isModalVisible, onClose }: IModalNewList) => {
	const queryClient = useQueryClient();
	const createFormMutation = useMutation({
		mutationFn: createList,
		onSuccess: () => {
			toast.success('Formulario creado exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['lists'] });
		},
	});

	async function handleSave(
		formData: {
			title: string;
			items: {
				id: string;
				backendId?: string;
				value: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) {
		await createFormMutation.mutateAsync({
			title: formData.title,
			detail: formData.items.map(field => field.value),
		});
		if (onSuccess) onSuccess();
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Crear Lista'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					canSaveFn={({ fields }) =>
						// Every field is filled
						fields.value.filter(item => !!item.data.value).length === fields.value.length
					}
					initialState={[
						{
							id: '',
							data: { id: '', value: '' },
							isBeingEdited: false,
							isValid: false,
						},
					]}
					key={isModalVisible + ''}
					isLoading={createFormMutation.isPending}
					onSave={handleSave}
					onClose={onClose}
				/>
			</FormModalProvider>
		</Modal>
	);
};
