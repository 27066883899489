import { useState } from 'react';

import { DrawableMap, FormNewLocation } from '@organisms';

import type { IViewState, IPoint, IModalProps } from '@interfaces';

import { Modal } from '../Modal';

const DEFAULT_VIEW_STATE: IViewState = {
	lng: -78.1834,
	lat: -1.8312,
	zoom: 6.5,
} as const;

export const ModalNewLocation = ({ isVisible = false, onCloseModal = () => {} }: IModalProps) => {
	const [viewState, setViewState] = useState<IViewState>(DEFAULT_VIEW_STATE);
	const [points, setPoints] = useState<IPoint[]>([]);
	const [markerCoords, setMarkerCoords] = useState<{ lat: number; lng: number } | undefined>();

	const handleCloseModal = () => {
		setPoints([]);
		onCloseModal();
	};

	const handleChangeCenterPoint = (viewState: IViewState, isPlace: boolean = false) => {
		setViewState(viewState);
		if (isPlace) {
			setMarkerCoords(undefined);
			return;
		}
		setMarkerCoords({
			lat: viewState.lat,
			lng: viewState.lng,
		});
	};

	return (
		<Modal className='flex min-h-[93vh]' closeButton={false} isOpen={isVisible} onClose={onCloseModal}>
			<div className='flex gap-8 w-full flex-grow flex-shrink'>
				<FormNewLocation
					onSubmit={handleCloseModal}
					onCancel={handleCloseModal}
					onChangeCenterPoint={handleChangeCenterPoint}
					points={points}
				/>
				<DrawableMap
					isVisible={isVisible}
					points={points}
					viewState={viewState}
					onFinishedDrawing={points => setPoints(points)}
					onDeleteDrawing={() => setPoints([])}
					markerCoords={markerCoords}
				/>
			</div>
		</Modal>
	);
};
