import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import { FormsPage, ListsPage, ProcessesPage, WorkflowsPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';

const processesSubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'all',
		tabName: 'Procesos',
		element: <ProcessesPage />,
		requiredPermission: 'INSPECTIONS_LIST', // TODO: Define permission
	}),
	createProtectedTabRoute({
		path: 'workflows',
		tabName: 'Flujos de Trabajo',
		element: <WorkflowsPage />,
		requiredPermission: 'INSPECTIONS_LIST', // TODO: Define permission
	}),
	createProtectedTabRoute({
		path: 'forms',
		tabName: 'Formularios',
		element: <FormsPage />,
		requiredPermission: 'INSPECTIONS_LIST', // TODO: Define permission
	}),
	createProtectedTabRoute({
		path: 'lists',
		tabName: 'Listas',
		element: <ListsPage />,
		requiredPermission: 'INSPECTIONS_LIST', // TODO: Define permission
	}),
];

export const processesRoutes: RouteObject[] = [
	{
		path: 'processes',
		element: <TabbedLayout tabRoutes={processesSubRoutes} />,
		children: processesSubRoutes,
	},
];
