import { useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';

import { deleteList, findAllLists, getOneList } from '@api';
import { Button, IcEdit, IcPlus, IcTrash } from '@atoms';
import { useConfirmation, useTableQuery } from '@hooks';
import { BaseTable, ModalConfirmation, ModalEditList, ModalNewList, TablePagination, TableSearchbar } from '@organisms';
import moment from 'moment';

import type { IList } from '@interfaces';

const columnHelper = createColumnHelper<IList>();
const listsColumns = [
	columnHelper.accessor('title', {
		header: 'Nombre de Lista',
	}),
	columnHelper.accessor('createdAt', {
		header: 'Fecha de Creación',
		cell: context => moment.utc(context.getValue()).format('DD/MM/YYYY HH:mm'),
	}),
];

const ListsPage = () => {
	const { confirm, confirmationModalProps } = useConfirmation({
		mutationFn: deleteList,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ['lists'] }),
	});

	const [editingListId, setEditingListId] = useState('');
	const [isModalVisible, setIsModalVisible] = useState(false);

	const queryClient = useQueryClient();
	const { data } = useQuery({
		queryKey: ['lists', editingListId],
		queryFn: () => getOneList({ listId: editingListId }),
		enabled: !!editingListId,
	});
	const tableQueryResult = useTableQuery({
		queryKey: ['lists'],
		fetcherFn: findAllLists,
		tableColumns: [
			...listsColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					// console.log(id);
					return (
						<div className='flex justify-end gap-4'>
							<IcEdit
								onClick={() => handleEditList(id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcTrash
								onClick={() => confirm({ id })}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	function handleEditList(id: string) {
		setEditingListId(id);
	}

	function handleCloseEditModal() {
		queryClient.invalidateQueries({
			queryKey: ['lists', editingListId],
		});
		setEditingListId('');
	}

	return (
		<>
			<ModalConfirmation
				{...confirmationModalProps}
				confirmationMessage='¿Estás seguro de eliminar la lista?'
				confirmLabel='Eliminar'
			/>

			<ModalEditList
				initialListData={data ?? { id: '', title: '', detail: [] }}
				isModalVisible={!!data && !!editingListId}
				onClose={handleCloseEditModal}
			/>
			<ModalNewList isModalVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => setIsModalVisible(true)}>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Lista
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default ListsPage;
