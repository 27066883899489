import { apiPrivate } from '@api';

interface IGetOneProcessResponse {
	id: string;
	title: string;
	referenceTitle: string;
	referenceType: {
		id: string;
		name: string;
	};
	processLocations: {
		id: string;
		companyLocation: {
			id: string;
			location: string;
		};
	}[];
	workflowGroup: {
		id: string;
		order: number;
		workflowHeader: {
			id: string;
			title: string;
		};
	}[];
}

interface IProcess {
	id: string;
	title: string;
	referenceTitle: string;
	referenceTypeId: string;
	locationsIds: string[];
	workflows: {
		fieldId: string;
		id: string; // backendId
		title: string;
	}[];
}

interface IGetOneProcessParams {
	processId: string;
}

const acl = (param: IGetOneProcessResponse): IProcess => ({
	id: param.id,
	referenceTitle: param.referenceTitle,
	referenceTypeId: param.referenceType.id,
	title: param.title,
	locationsIds: param.processLocations.map(processLocation => processLocation.companyLocation.id),
	workflows: param.workflowGroup.map(workflowGroup => ({
		fieldId: workflowGroup.id,
		id: workflowGroup.workflowHeader.id,
		title: workflowGroup.workflowHeader.title,
	})),
});

export const getOneProcess = async (params: IGetOneProcessParams): Promise<IProcess> => {
	const { data } = await apiPrivate.get<IGetOneProcessResponse>(`/api/workflow/process/${params.processId}`);
	return acl(data);
};
