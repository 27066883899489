import { apiPrivate } from '../config/apiPrivate';

interface IUpdateListParams {
	id: string;
	title: string;
	itemsDetail: {
		id?: string; // backend id, if not present it will create the item
		detail: string;
		isActive?: boolean;
	}[];
}

export const updateList = async ({ id: listId, ...restParams }: IUpdateListParams) => {
	const { data } = await apiPrivate.patch(`/api/workflow/custom-list/${listId}`, restParams);
	return data;
};
