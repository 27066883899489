import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal } from '../Modal';
import { InnerForm } from './InnerForm';
import { createProcess } from '@api';
import toast from 'react-hot-toast';
import styles from '../Forms/overrides.module.sass';
import { IFormData } from './interfaces';
import { FormModalProvider } from '../Forms/ctx';

interface IModalNewForm {
	isModalVisible: boolean;
	onClose: () => void;
}

export const ModalNewProcess = ({ isModalVisible, onClose }: IModalNewForm) => {
	const queryClient = useQueryClient();
	const createFormMutation = useMutation({
		mutationFn: createProcess,
		onSuccess: () => {
			toast.success('Proceso creado exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['processes'] });
		},
	});

	async function handleSave(formData: IFormData, onSuccess?: () => void) {
		const { formTitle, identifierTitle, identifierTypeId, locationsIds } = formData.headerForm;
		await createFormMutation.mutateAsync({
			title: formTitle,
			referenceTitle: identifierTitle,
			referenceType: identifierTypeId,
			workflowHeader: formData.items.map(item => ({ id: item.id || '' })),
			Locations: locationsIds.map(location => ({ id: location })),
		});
		if (onSuccess) onSuccess();
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Crear Proceso'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					canSaveFn={({ fields }) => fields.value.filter(item => item.isValid).length === fields.value.length}
					key={isModalVisible + ''}
					isLoading={createFormMutation.isPending}
					onSave={handleSave}
					onClose={onClose}
				/>
			</FormModalProvider>
		</Modal>
	);
};
