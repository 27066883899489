import { apiPrivate } from '../config/apiPrivate';

interface IDeleteListParams {
	id: string;
}

export const deleteList = async (params: IDeleteListParams) => {
	const { data } = await apiPrivate.delete(`/api/workflow/custom-list/${params.id}`);
	return data;
};
