import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox } from '@molecules';
import toast from 'react-hot-toast';

interface IQrScanFieldFormValues {
	title: string;
	isRequired: boolean;
}

interface IQrScanFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

export function FormQrScanField({ defaultValues, onSave, onUndo, title }: IQrScanFieldFormProps & { title: string }) {
	const id = '33137d17-4776-4345-a524-325715112652';
	const form = useForm<IQrScanFieldFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: IQrScanFieldFormValues) {
		console.log('form submitted with values', formValues);
		toast.success('Campo guardado.', { position: 'bottom-right' });
		onSave({
			id,
			label: formValues.title,
			defaultValue: '',
			isRequired: formValues.isRequired,
		});

		form.reset({
			title,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
