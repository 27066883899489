import { Control, Controller, FieldValues, Path, useForm, useFormState } from 'react-hook-form';
import { Field, IDraggableFormState, IFieldValues } from '../Forms/interfaces';
import { useDraggableForm } from '../Forms/useDraggableForm';
import { Fragment } from 'react/jsx-runtime';
import {
	DeleteButton,
	DraggableItem,
	DraggableList,
	FormInput,
	FormMultiCheckSelect,
	FormSearchableDropdown,
	MoveButton,
} from '@molecules';
import { Button, ButtonDashed } from '@atoms';
import { useFormModalContext } from '../Forms/ctx';
import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { findAllReferenceTypes, findAllWorkflows, findLocationsByCompany } from '@api';
import { useAppSelector } from '@hooks';
import { IFormData, IInnerFormValues } from './interfaces';

interface ITitleFieldFormProps {
	id: string;
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

export function TitleForm({ id, defaultValues, onSave }: ITitleFieldFormProps & { title: string }) {
	const [isDirty, setIsDirty] = useState(() => defaultValues.id !== id);
	// Not the sexiest thing on earth
	useEffect(() => {
		setIsDirty(defaultValues.id !== id);
	}, [id]);

	function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		onSave({ id, isRequired: false, label: '', defaultValue: '' });
		setIsDirty(false);
	}

	return (
		<form autoComplete='off' onSubmit={handleSubmit} className='flex flex-col justify-between flex-grow'>
			<div className='flex gap-4'>
				<Button className='text-sm h-10' disabled={!isDirty} type='submit'>
					Guardar Cambios
				</Button>
			</div>
		</form>
	);
}

const WorkflowsSearchableDropdown = ({
	value,
	onChange,
}: {
	value: string;
	onChange: (params: { id: string; value: string }) => void;
}) => {
	const { data } = useQuery({
		queryFn: () => findAllWorkflows({ page: 0, limit: 1000000, sorting: [], searchTerm: '' }),
		queryKey: [],
	});

	return (
		<FormSearchableDropdown
			value={value}
			onChange={newId =>
				onChange({ id: newId, value: data?.data.find(workflow => workflow.id === newId)?.title ?? '' })
			}
			label='Flujo de trabajo'
			options={[
				{
					id: '',
					value: 'Selecciona un flujo de trabajo',
				},
				...(data?.data.map(workflow => ({ id: workflow.id, value: workflow.title })) ?? []),
			]}
		/>
	);
};

export function IdentifierForm({ id, defaultValues, onSave }: ITitleFieldFormProps & { title: string }) {
	const [isDirty, setIsDirty] = useState(() => defaultValues.id !== id);
	// Not the sexiest thing on earth
	useEffect(() => {
		setIsDirty(defaultValues.id !== id);
	}, [id]);
	const { showError } = useFormModalContext();
	const canSubmit = true;

	function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		onSave({ id, isRequired: false, label: '', defaultValue: '' });
		setIsDirty(false);
	}

	return (
		<form autoComplete='off' onSubmit={handleSubmit} className='flex flex-col justify-between flex-grow'>
			<div className='flex flex-col gap-6'>
				<div
					className={`${showError && canSubmit ? 'opacity-100' : 'opacity-0'} duration-500 ease-out transition-opacity self-start rounded-md bg-[#FECDCA] border border-[#FDA29B] p-2 text-xs text-bummock-dark_grey`}
				>
					Los cambios no han sido guardados.
				</div>
			</div>
			<div className='flex gap-4'>
				<Button className='text-sm h-10' disabled={!isDirty} type='submit'>
					Guardar Cambios
				</Button>
			</div>
		</form>
	);
}

interface IFormMultiCheckDropdownLocationsProps<T extends FieldValues> {
	name: Path<T>;
	control: Control<T>;
}
const FormMultiCheckDropdownLocations = <T extends FieldValues>({
	name,
	control,
}: IFormMultiCheckDropdownLocationsProps<T>) => {
	const { companies } = useAppSelector(state => state.userProfile);
	const companyId = companies.at(0)?.id ?? '';
	const formState = useFormState({ control });

	const { data = [] } = useQuery({
		queryFn: () => findLocationsByCompany({ companyId }),
		queryKey: ['locations-by-company', companyId],
	});

	return (
		<Controller
			control={control}
			name={name}
			// @ts-expect-error will fix later
			defaultValue=''
			rules={{ required: { value: true, message: 'Selecciona al menos una localidad' } }}
			render={({ field }) => {
				return (
					<FormMultiCheckSelect
						className='grow basis-0 shrink text-sm'
						required
						label='Localidades'
						selectedOptions={field.value || []}
						errorMessage={formState.errors[name]?.message?.toString()}
						onError={!!formState.errors[name]?.message?.toString()}
						options={data.map(location => ({ id: location.id, value: location.name }))}
						{...field}
					/>
				);
			}}
		/>
	);
};

interface IFormSearchableDropdownIdentifierTypeProps<T extends FieldValues> {
	name: Path<T>;
	control: Control<T>;
}
const FormSearchableDropdownIdentifierType = <T extends FieldValues>({
	name,
	control,
}: IFormSearchableDropdownIdentifierTypeProps<T>) => {
	const formState = useFormState({ control });

	const { data = [] } = useQuery({
		queryFn: () => findAllReferenceTypes(),
		queryKey: ['identifier-type'],
	});

	return (
		<Controller
			control={control}
			name={name}
			// @ts-expect-error will fix later
			defaultValue=''
			rules={{ required: { value: true, message: 'Selecciona un tipo de identificador' } }}
			render={({ field }) => (
				<FormSearchableDropdown
					required
					className='grow basis-0 shrink text-sm'
					label='Tipo de Identificador'
					errorMessage={formState.errors[name]?.message?.toString()}
					onError={!!formState.errors[name]?.message?.toString()}
					options={data.map(({ id, name }) => ({ id, value: name }))}
					{...field}
				/>
			)}
		/>
	);
};

interface IInnerFormProps {
	onClose: () => void;
	defaultFormHeaderValues?: IInnerFormValues;
	initialState?: IDraggableFormState;
	onSave: (formData: IFormData, onSuccess?: () => void) => void;
	canSaveFn?: (params: {
		title: {
			value: string;
			isDirty: boolean;
		};
		fields: {
			value: Field[];
			isDirty: boolean;
		};
	}) => boolean;
	isLoading: boolean;
}

export function InnerForm({
	isLoading,
	defaultFormHeaderValues,
	canSaveFn = () => true,
	initialState,
	onSave,
}: IInnerFormProps) {
	const form = useForm<IInnerFormValues>({
		defaultValues: { ...defaultFormHeaderValues },
	});

	const { title, fields, editingField, areFieldsDirty, handlers, fieldsContainerElementRef } = useDraggableForm({
		initialState,
	});
	const {
		handleAddItem,
		handleCancel,
		handleChangeFieldType,
		handleDeleteField,
		handleEnableItemEdition,
		handleMoveDown,
		handleMoveUp,
		handleSaveItem,
		handleSetItems,
		handleReset,
		handleChangeTitle,
	} = handlers;

	function handleSaveForm(formValues: IInnerFormValues) {
		console.log('submitted with', formValues);
		onSave(
			{
				headerForm: formValues,
				items: fields.map(({ data: field, backendId }, index) => ({
					id: field.id,
					fieldId: backendId,
					isRequired: field.isRequired,
					defaultValue: field.defaultValue || '',
					label: field.label,
					order: index.toString(),
				})),
			},
			handleReset
		);
	}

	// Should not be a part of this component
	function renderForm(fieldType: string) {
		const props = {
			title,
			defaultValues: editingField.data,
			onSave: (formValues: IFieldValues) =>
				handleSaveItem({
					title,
					formValues,
				}),
			onDelete: handleCancel,
		};

		return <TitleForm id={fieldType} {...props} />;
	}

	const canSave = canSaveFn({
		title: {
			value: '',
			isDirty: form.formState.isDirty,
		},
		fields: {
			value: fields,
			isDirty: areFieldsDirty,
		},
	});

	return (
		<Fragment>
			<p className='text-xs'>
				Personaliza un nuevo proceso definiendo sus workflows y opciones de visualización. Todos los cambios se
				pueden modificar más adelante.
			</p>

			<div className='flex gap-4'>
				<FormInput
					className='grow basis-5/12 shrink'
					required
					errorMessage={form.formState.errors.formTitle?.message}
					onError={!!form.formState.errors.formTitle}
					register={form.register('formTitle', {
						required: { message: 'Ingresa un título', value: true },
					})}
					label='Título'
					placeholder='Título de Proceso'
				/>
				<FormMultiCheckDropdownLocations control={form.control} name='locationsIds' />
			</div>

			<div className='flex gap-4'>
				<FormSearchableDropdownIdentifierType name='identifierTypeId' control={form.control} />
				<FormInput
					className='grow basis-0 shrink'
					required
					errorMessage={form.formState.errors.identifierTitle?.message}
					onError={!!form.formState.errors.identifierTitle}
					register={form.register('identifierTitle', {
						required: { message: 'Ingresa un identificador', value: true },
					})}
					label='Título del Identificador'
					placeholder='Título'
				/>
			</div>

			<hr />

			<div className='flex gap-4'>
				<div className='basis-0 grow'>
					<h2 className='text-xs font-semibold text-bummock-midnight_blue mb-2'>Campos del proceso</h2>
					<div className='flex flex-col gap-4 h-[465px]'>
						<div
							ref={fieldsContainerElementRef}
							className='flex flex-col gap-4 overflow-x-hidden overflow-scroll pr-4'
						>
							<DraggableList items={fields} setItems={handleSetItems}>
								{fields.map((item, index) => (
									<DraggableItem
										key={item.id}
										id={item.id}
										label={item.data.label}
										isBeingEdited={item.isBeingEdited}
										onEdit={() => {
											handleEnableItemEdition(item.id);
										}}
									>
										{({ isBeingEdited, isDragging, label }) => (
											<>
												{label === 'Separador' ? (
													<div
														className={`h-[1px] flex-grow ${isBeingEdited ? 'bg-white' : 'bg-black'}`}
													></div>
												) : (
													<span
														className={`select-none cursor-text basis-[17.75rem] grow-[2] text-sm shrink-0 h-9 rounded-lg flex items-center p-4 ${!isBeingEdited ? 'bg-bummock-off_white shadow-bummock' : ''} ${isBeingEdited ? 'text-white' : label ? 'text-black' : 'text-bummock-disabled_grey_text'}`}
													>
														{label || 'Ejemplo: Nombre del cliente'}
													</span>
												)}
												<MoveButton
													variant={isBeingEdited ? 'dark' : 'light'}
													disabled={index === 0}
													onClick={() => handleMoveUp(index)}
													isBeingEdited={isBeingEdited}
													direction='up'
												/>
												<MoveButton
													variant={isBeingEdited ? 'dark' : 'light'}
													disabled={index >= fields.length - 1}
													onClick={() => handleMoveDown(index)}
													isBeingEdited={isBeingEdited}
													direction='down'
												/>
												<DeleteButton
													variant={isBeingEdited ? 'dark' : 'light'}
													isBeingEdited={isBeingEdited}
													isDragging={isDragging}
													onClick={() => handleDeleteField(item.id)}
													disabled={fields.length === 1}
												/>
											</>
										)}
									</DraggableItem>
								))}
							</DraggableList>
						</div>
						<ButtonDashed onClick={handleAddItem}>Agregar otro paso</ButtonDashed>
					</div>
				</div>
				<div className='w-[1px] bg-bummock-disabled_grey'></div>
				<div className='basis-0 grow flex flex-col'>
					<div className='flex flex-col gap-6 grow'>
						<WorkflowsSearchableDropdown
							value={editingField.type}
							onChange={({ id, value }) => {
								handleChangeFieldType(id);
								handleChangeTitle(value);
							}}
						/>
						{editingField.type ? (
							<Fragment key={editingField.id}>{renderForm(editingField.type)}</Fragment>
						) : null}
					</div>
				</div>
			</div>
			<Button
				disabled={!canSave}
				isLoading={isLoading}
				onClick={form.handleSubmit(handleSaveForm)}
				className='text-sm ml-auto h-10'
			>
				Guardar Flujo de Trabajo
			</Button>
		</Fragment>
	);
}
