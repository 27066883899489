import { apiPrivate } from '../config/apiPrivate';

interface IDeleteWorkflowParams {
	id: string;
}

export const deleteWorkflow = async (params: IDeleteWorkflowParams) => {
	const { data } = await apiPrivate.delete(`/api/workflow/delete/${params.id}`);
	return data;
};
