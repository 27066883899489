import { apiPrivate } from '../config/apiPrivate';

interface ICreateProcessParams {
	title: string;
	referenceTitle: string;
	referenceType: string;
	workflowHeader: {
		id: string;
	}[];
	Locations: {
		id: string;
	}[];
}

export const createProcess = async (params: ICreateProcessParams) => {
	const { data } = await apiPrivate.post('/api/workflow/process', params);
	return data;
};
