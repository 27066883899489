import { Fragment, useReducer, useRef } from 'react';
import { useFormModalContext } from '../Forms/ctx';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import type { IItem } from '@interfaces';
import { arrayMove } from '@dnd-kit/sortable';
import { DeleteButton, DraggableItem, DraggableList, FormInput, MoveButton } from '@molecules';
import { Button, DashedContainer, IcAdd } from '@atoms';

type Action =
	| {
			type: 'add' | 'reset';
	  }
	| {
			type: 'replace';
			payload: IField[];
	  }
	| {
			type: 'edit';
			payload: string;
	  }
	| {
			type: 'delete';
			payload: string;
	  }
	| {
			type: 'save';
			payload: {
				fieldId: string;
				value: string;
			};
	  };

interface IField {
	id: string; // This is the internal (frontend only) ID
	isBeingEdited: boolean;
	isValid: boolean;
	data: {
		id: string; // Backend ID
		value: string; // this one gets updated when the user types
	};
}

const INITIAL_STATE: IField[] = [
	{
		id: v4(),
		isBeingEdited: true,
		isValid: false,
		data: {
			id: '',
			value: '',
		},
	},
];

function reducer(oldState: IField[], action: Action): IField[] {
	switch (action.type) {
		case 'reset': {
			return INITIAL_STATE;
		}
		case 'add': {
			const newState = oldState.map(field => ({
				...field,
				isBeingEdited: false,
			}));
			return [
				...newState,
				{
					id: v4(),
					isBeingEdited: true,
					isValid: false,
					data: {
						id: '',
						value: '',
					},
				},
			];
		}
		case 'replace': {
			return action.payload;
		}
		case 'edit': {
			const newState = oldState.map(field => ({
				...field,
				isBeingEdited: false,
			}));
			const clickedItem = newState.find(field => field.id === action.payload);
			if (!clickedItem) return oldState;
			clickedItem.isBeingEdited = true;
			return newState;
		}
		case 'delete': {
			if (oldState.length === 1) return oldState;
			const newState = oldState
				.filter(field => field.id !== action.payload)
				.map(field => ({ ...field, isBeingEdited: false }));

			const isEditingLastItem = oldState.at(-1)?.isBeingEdited;
			if (isEditingLastItem) {
				const newLastElement = newState.at(-1);
				if (!newLastElement) return oldState;
				newLastElement.isBeingEdited = true;
			} else {
				const editingFieldIndex = oldState.findIndex(field => field.isBeingEdited);
				if (editingFieldIndex === -1) return oldState;
				newState[editingFieldIndex].isBeingEdited = true;
			}
			return newState;
		}
		case 'save': {
			const newState = [...oldState];
			const { fieldId, value } = action.payload;
			const foundField = newState.find(field => field.id === fieldId);
			if (!foundField) return oldState;
			foundField.data.value = value;
			foundField.isValid = true;
			return newState;
		}
		default:
			return oldState;
	}
}

interface IInnerFormProps {
	onClose: () => void;
	initialFormTitle?: string;
	initialState?: IField[];
	onSave: (
		formData: {
			title: string;
			items: {
				id: string; // frontend id
				backendId?: string;
				value: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) => void;
	isLoading: boolean;
	canSaveFn?: (params: {
		title: {
			value: string;
			isDirty: boolean;
		};
		fields: {
			value: IField[];
			isDirty: boolean;
		};
	}) => boolean;
}

export function InnerForm({
	isLoading,
	initialFormTitle = '',
	initialState,
	onSave,
	canSaveFn = () => true,
}: IInnerFormProps) {
	const [items, dispatch] = useReducer(reducer, initialState?.length ? structuredClone(initialState) : INITIAL_STATE);
	const { setShowError, canSubmit } = useFormModalContext();
	const fieldsContainerElementRef = useRef<HTMLDivElement>(null);

	const form = useForm<{ formTitle: string }>({
		defaultValues: { formTitle: initialFormTitle },
	});

	function showErrorForSeconds(value: boolean, seconds: number = 3) {
		setShowError(value);
		setTimeout(() => setShowError(false), seconds * 1000);
	}

	function handleAddItem() {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'add' });

		if (!fieldsContainerElementRef.current) return;
		const fieldsContainer = fieldsContainerElementRef.current;
		// Timeout to make sure the DOM has been updated
		setTimeout(() =>
			fieldsContainer.scrollTo({
				top: fieldsContainer.scrollHeight,
				behavior: 'smooth',
			})
		);
	}

	function handleEnableItemEdition(id: string) {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'edit', payload: id });
	}

	function handleSetItems(
		stateUpdaterFn: (oldState: Omit<IItem, 'label'>[]) => {
			oldIndex: number;
			newIndex: number;
		}
	) {
		const { oldIndex, newIndex } = stateUpdaterFn(items);
		const swappedArray = arrayMove(items, oldIndex, newIndex);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleMoveUp(index: number) {
		const swappedArray = arrayMove(items, index, index - 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleMoveDown(index: number) {
		const swappedArray = arrayMove(items, index, index + 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleDeleteField(id: string) {
		dispatch({ type: 'delete', payload: id });
	}

	function handleSaveForm(formValues: { formTitle: string }) {
		console.log('submitted with', formValues);
		onSave(
			{
				title: formValues.formTitle,
				items: items.map(({ id, data: field }, index) => ({
					id: id,
					backendId: field.id,
					value: field.value,
					order: index.toString(),
				})),
			},
			() => dispatch({ type: 'reset' })
		);
	}

	function handleUpdateInput(id: string, value: string) {
		console.log('Updating input', id, 'with value', value);
		dispatch({ type: 'save', payload: { fieldId: id, value } });
	}

	const areFieldsDirty = items.reduce((prev, curr, index) => {
		if (!initialState) return true;
		const fromProps = initialState.at(index);
		if (!fromProps) return true;
		if (fromProps.data.value !== curr.data.value) return true;
		return prev;
	}, false);

	const canSave = canSaveFn({
		title: {
			value: '',
			isDirty: form.formState.isDirty,
		},
		fields: {
			value: items,
			isDirty: areFieldsDirty,
		},
	});

	return (
		<Fragment>
			<p className='text-xs'>Crea una lista que podrá ser usada en tus formularios.</p>

			<FormInput
				required
				errorMessage={form.formState.errors.formTitle?.message}
				onError={!!form.formState.errors.formTitle}
				register={form.register('formTitle', {
					required: { message: 'Ingresa un título', value: true },
				})}
				label='Título'
				placeholder='Título de Lista'
			/>

			<hr />

			<div className='flex gap-4'>
				<div className='basis-0 grow'>
					<h2 className='text-xs font-semibold text-bummock-midnight_blue mb-2'>Pasos del flujo</h2>
					<div className='flex flex-col gap-4 h-[465px]'>
						<div
							ref={fieldsContainerElementRef}
							className='flex flex-col gap-4 overflow-x-hidden overflow-scroll pr-4'
						>
							<DraggableList items={items} setItems={handleSetItems}>
								{items.map((item, index) => (
									<DraggableItem
										className='!bg-bummock-off_white'
										key={item.id}
										id={item.id}
										label={item.data.value}
										isBeingEdited={item.isBeingEdited}
										onEdit={() => handleEnableItemEdition(item.id)}
									>
										{({ isBeingEdited, isDragging }) => (
											<>
												<input
													className='!h-9 text-xs'
													placeholder='Ejemplo: Nombre del cliente'
													value={item.data.value}
													onChange={e => handleUpdateInput(item.id, e.target.value)}
												/>
												<MoveButton
													disabled={index === 0}
													onClick={() => handleMoveUp(index)}
													isBeingEdited={isBeingEdited}
													direction='up'
												/>
												<MoveButton
													disabled={index >= items.length - 1}
													onClick={() => handleMoveDown(index)}
													isBeingEdited={isBeingEdited}
													direction='down'
												/>
												<DeleteButton
													isBeingEdited={isBeingEdited}
													isDragging={isDragging}
													onClick={() => handleDeleteField(item.id)}
													disabled={items.length === 1}
												/>
											</>
										)}
									</DraggableItem>
								))}
							</DraggableList>
						</div>
						<DashedContainer
							onClick={handleAddItem}
							className='cursor-pointer flex shrink-0 gap-2 text-sm items-center'
						>
							<IcAdd />
							<span className='font-semibold text-bummock-midnight_blue text-xs'>Agregar otro item</span>
						</DashedContainer>
					</div>
				</div>
			</div>
			<Button
				disabled={!canSave}
				isLoading={isLoading}
				onClick={form.handleSubmit(handleSaveForm)}
				className='text-sm ml-auto px-[1.65625rem] h-10'
			>
				Guardar Lista
			</Button>
		</Fragment>
	);
}
