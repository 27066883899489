import { Button, IcEdit, IcPlus, IcTrash } from '@atoms';
import {
	BaseTable,
	ModalConfirmation,
	ModalEditProcess,
	ModalNewProcess,
	TablePagination,
	TableSearchbar,
} from '@organisms';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { deleteProcess, findAllProcesses, getOneProcess, queryClient } from '@api';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useConfirmation, useTableQuery } from '@hooks';

const columnHelper = createColumnHelper<{ id: string; title: string }>();
const processesColumns: ColumnDef<{ id: string; title: string }, string>[] = [
	columnHelper.accessor('id', {
		header: 'ID',
		cell: ({ row }) => row.original.id.slice(-6),
		meta: { alignment: 'left' },
	}),
	columnHelper.accessor('title', {
		header: 'Nombre',
		meta: { alignment: 'left' },
	}),
];

const ProcessesPage = () => {
	const { confirm, confirmationModalProps } = useConfirmation({
		mutationFn: deleteProcess,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ['processes'] }),
	});

	const [editingProcessId, setEditingProcessId] = useState('');
	const { data } = useQuery({
		queryKey: ['processes', editingProcessId],
		queryFn: () => getOneProcess({ processId: editingProcessId }),
		enabled: !!editingProcessId,
	});
	const [isModalVisible, setIsModalVisible] = useState(false);

	const tableQueryResult = useTableQuery({
		queryKey: ['processes'],
		fetcherFn: findAllProcesses,
		tableColumns: [
			...processesColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcEdit
								onClick={() => handleEditProcess(id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcTrash
								onClick={() => confirm({ id })}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	function handleEditProcess(id: string) {
		// Get form data
		// Show modal with populated data
		setEditingProcessId(id);
	}

	function handleCloseEditModal() {
		queryClient.invalidateQueries({
			queryKey: ['form', editingProcessId],
		});
		setEditingProcessId('');
	}

	return (
		<>
			<ModalConfirmation {...confirmationModalProps} />

			<ModalEditProcess
				initialProcessData={
					data
						? {
								id: data.id,
								headerForm: {
									formTitle: data.title,
									identifierTitle: data.referenceTitle,
									identifierTypeId: data.referenceTypeId,
									locationsIds: data.locationsIds,
								},
								items: data.workflows.map(workflow => ({
									id: workflow.id,
									fieldId: workflow.fieldId,
									defaultValue: '',
									isRequired: false,
									label: workflow.title,
									order: '',
								})),
							}
						: {
								id: '',
								headerForm: {
									formTitle: '',
									identifierTitle: '',
									identifierTypeId: '',
									locationsIds: [],
								},
								items: [],
							}
				}
				isModalVisible={!!data && !!editingProcessId}
				onClose={handleCloseEditModal}
			/>

			<ModalNewProcess isModalVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => setIsModalVisible(true)}>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Proceso
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default ProcessesPage;

// El botón de guardar debe estar deshabilitado si hay campos pendientes

// Esos formularios creados aparecen en /api/workflow/processes
// pero también hay otros y no está la estructura que necesito para llenar el formulario con los datos por defecto.

// Todos aceptan un valor por defecto, pero para algunos campos esto no tiene sentido.
