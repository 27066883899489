import { Modal } from '../Modal';
import styles from '../Forms/overrides.module.sass';
import { FormModalProvider } from '../Forms/ctx';
import { InnerForm } from './InnerForm';
import { PROCESS_TYPE_BY_ID } from '@utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editWorkflow } from '@api';
import toast from 'react-hot-toast';

interface IModalEditWorkflowProps {
	initialWorkflowData: {
		id: string;
		name: string;
		details: {
			id: string;
			// order: 0; // this could be unnecessary
			label: string;
			defaultValue: string; // This is always empty. Request fix
			isRequired: boolean; // Im not getting the required either
			isActive: boolean;
			componentDataType: {
				id: string;
			};
		}[];
	};
	isModalVisible: boolean;
	onClose: () => void;
}

export const ModalEditWorkflow = ({ initialWorkflowData, isModalVisible, onClose }: IModalEditWorkflowProps) => {
	const queryClient = useQueryClient();
	const editWorkflowMutation = useMutation({
		mutationFn: editWorkflow,
		onSuccess: () => {
			toast.success('Workflow editado exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['workflows'] });
		},
	});

	async function handleSave(
		formData: {
			title: string;
			items: {
				fieldId?: string;
				id: string;
				isRequired: boolean;
				defaultValue?: string;
				label: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) {
		console.log(formData.items);
		await editWorkflowMutation.mutateAsync({
			workflowId: initialWorkflowData.id,
			title: formData.title,
			items: formData.items.map(field => ({
				...(field.fieldId ? { id: field.fieldId } : {}),
				formHeader: field.id ?? '',
				title: field.label,
				isRequired: field.isRequired,
				defaultValue: field.defaultValue,
			})),
		});
		if (onSuccess) onSuccess();
		console.log('trynna save', formData);
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Editar Flujo de Trabajo'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					key={initialWorkflowData.details.length}
					isLoading={editWorkflowMutation.isPending}
					onClose={onClose}
					initialFormTitle={initialWorkflowData.name}
					canSaveFn={({ title, fields }) =>
						title.isDirty || (fields.isDirty && fields.value.every(field => field.isValid))
					}
					initialState={{
						title: initialWorkflowData.details.at(0)?.label ?? '',
						fields: initialWorkflowData.details.map((detail, index) => ({
							id: detail.id,
							backendId: detail.id,
							data: {
								id: detail.componentDataType.id,
								defaultValue: detail.defaultValue,
								isRequired: detail.isRequired,
								label: detail.label,
							},
							isBeingEdited: index === 0,
							isValid: true,
							// Formulario by default as it is the only one that varies (this will break for sure lol)
							type: PROCESS_TYPE_BY_ID[detail.componentDataType.id] ?? 'Formulario',
						})),
					}}
					onSave={handleSave}
				/>
			</FormModalProvider>
		</Modal>
	);
};
