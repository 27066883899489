import { useEffect, useImperativeHandle, useRef, useState } from 'react';

import type { ChangeEvent, MouseEvent } from 'react';

import { Button, IcUpload2 } from '@atoms';
import { ACCEPTED_FORMATS } from '@utils';

import type { IImagePickerProps } from './ImagePicker.interfaces';

import styles from './ImagePicker.module.sass';

function generateImgSrc(picture: File): Promise<string> {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.readAsDataURL(picture);
		reader.addEventListener('load', e => {
			const newImageSrc = e.target?.result as string;
			resolve(newImageSrc);
		});
	});
}

function generateFileList(fileBlob: Blob) {
	const file = new File([fileBlob], 'image.png', { type: 'image/png' });
	const fileList = new DataTransfer();
	fileList.items.add(file);
	return fileList.files;
}

interface IImageProps {
	className?: string;
	src: string;
	alt?: string;
}

const Image = ({ className = '', alt = '', src }: IImageProps) => {
	const [isLoading, setIsLoading] = useState(true);
	return (
		<img
			loading='eager'
			className={`${isLoading ? 'blur-sm' : ''} ${className}`}
			onLoad={() => setIsLoading(false)}
			src={src}
			alt={alt}
		/>
	);
};

export const ImagePicker = ({
	className = '',
	disabled,
	errorMessage,
	name,
	onChange,
	onError,
	register,
	required,
	profilePictureSrc,
	setValue,
	placeHolderImage = '',
	label = 'Cargar Imagen',
}: IImagePickerProps) => {
	const [imgSrc, setImgSrc] = useState<string>(placeHolderImage || profilePictureSrc || '/profile-image.png');
	const fileInputRef = useRef<HTMLInputElement>(null);

	// Synchronizes img src with file input value
	useEffect(() => {
		if (!profilePictureSrc) return;
		fetch(profilePictureSrc)
			.then(result => result.blob())
			.then(imageBlob => {
				const fileList = generateFileList(imageBlob);
				setValue && setValue(fileList);

				if (!fileInputRef.current) return;
				fileInputRef.current.files = fileList;
			})
			.catch(e => console.error(e));
	}, [profilePictureSrc]);

	// Exposes ref to the parent component (form)
	useImperativeHandle(register?.ref, () => fileInputRef.current);

	function handleClickUploadPicture(e: MouseEvent<HTMLButtonElement>) {
		e.preventDefault();
		const fileInput = fileInputRef.current;
		if (!fileInput) return;
		// Triggers the actual file input to open the dialog window that allows
		// the user to select a picture
		fileInput.click();
	}

	async function handleChangePicture(e: ChangeEvent<HTMLInputElement>) {
		const picture = e.target.files?.[0];
		if (!picture) return;

		const pictureSizeInMB = picture.size / (1024 * 1024);
		if (pictureSizeInMB >= 2) {
			console.error('File cannot exceed 2 MB');
			return;
		}

		const newImageSrc = await generateImgSrc(picture);
		setImgSrc(newImageSrc);

		// Calls the onChange function generated by react-hook-form
		register?.onChange(e);

		onChange && onChange(e);
	}

	return (
		<div className={`${className} ${styles['image-picker']}`}>
			<Image className={styles['image-picker__thumbnail']} src={imgSrc} alt='User profile picture' />
			<div className={styles['image-picker__controls-container']}>
				<Button
					variant='secondary'
					onClick={handleClickUploadPicture}
					className={styles['image-picker__controls-container__upload-button']}
				>
					<span>{label}</span>
					<IcUpload2 className='stroke-2 shrink-0 w-[20px] stroke-bummock-midnight_blue' />
				</Button>
				<input
					id='image-picker'
					className={styles['image-picker__controls-container__file-input']}
					accept={ACCEPTED_FORMATS.map(value => `.${value}`).join(', ')}
					multiple={false}
					type='file'
					{...{ disabled, name, required }}
					{...register}
					ref={fileInputRef}
					onChange={handleChangePicture}
				/>
				<p className={styles['image-picker__controls-container__label']}>
					Permitido: JPG, GIF o PNG. Tamaño máximo de 800 KB.
				</p>
				{onError ? (
					<span className={styles['image-picker__controls-container__error-message']}>{errorMessage}</span>
				) : null}
			</div>
		</div>
	);
};
