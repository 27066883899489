import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox } from '@molecules';
import toast from 'react-hot-toast';

interface IKitScanFielFormValues {
	title: string;
	isRequired: boolean;
}

interface IKitScanFielFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

export function FormKitSkanField({ defaultValues, onSave, onUndo, title }: IKitScanFielFormProps & { title: string }) {
	const id = '678f3dc6-f4fc-46e2-a14d-91cd2e656da1';
	const form = useForm<IKitScanFielFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: IKitScanFielFormValues) {
		console.log('form submitted with values', formValues);
		toast.success('Campo guardado.', { position: 'bottom-right' });
		onSave({
			id,
			label: formValues.title,
			defaultValue: '',
			isRequired: formValues.isRequired,
		});

		form.reset({
			title,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
