import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox } from '@molecules';

interface IInformativeTextFieldFormValues {
	title: string;
	isRequired: boolean;
}

interface IInformativeTextFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

export function FormInformativeTextField({
	defaultValues,
	onSave,
	onUndo,
	title,
}: IInformativeTextFieldFormProps & { title: string }) {
	const id = '36e7a5e7-3b85-4b0d-b8a7-1dc5c08173b4';
	const form = useForm<IInformativeTextFieldFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: IInformativeTextFieldFormValues) {
		console.log('form submitted with values', formValues);
		onSave({
			id,
			label: formValues.title,
			defaultValue: '',
			isRequired: false,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
