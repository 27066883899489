import { useReducer, useRef } from 'react';
import { v4 } from 'uuid';
import { IItem } from '@interfaces';
import { arrayMove } from '@dnd-kit/sortable';
import { useFormModalContext } from './ctx';
import { Field, IDraggableFormState, IFieldValues } from './interfaces';

type Actions =
	| {
			type: 'changeTitle';
			payload: string;
	  }
	| {
			type: 'add' | 'reset';
	  }
	| {
			type: 'cancel';
			payload: { id: string };
	  }
	| {
			type: 'undo';
			payload: { oldTitle: string };
	  }
	| {
			type: 'replace';
			payload: Field[];
	  }
	| {
			type: 'edit';
			payload: string;
	  }
	| {
			type: 'delete';
			payload: string;
	  }
	| {
			type: 'changeType';
			payload: {
				fieldId: string;
				newType: string;
			};
	  }
	| {
			type: 'saveFormData';
			payload: {
				fieldId: string;
				formData: IFieldValues;
			};
	  };

const INITIAL_STATE: IDraggableFormState = {
	title: '',
	fields: [
		{
			// id and backendId will be the same when editing
			id: v4(),
			// TODO: Add nullable backend ID
			backendId: '',
			isBeingEdited: true,
			isValid: false,
			type: '' as 'Formulario',
			data: {
				id: '',
				label: '',
				defaultValue: '',
				isRequired: true,
			},
		},
	],
};

function reducer(oldState: IDraggableFormState, action: Actions): IDraggableFormState {
	switch (action.type) {
		case 'changeTitle': {
			const { payload: title } = action;
			const newState = structuredClone(oldState);
			newState.title = title;
			return newState;
		}
		case 'reset': {
			return INITIAL_STATE;
		}
		case 'add': {
			const oldStateClone = structuredClone(oldState);
			const newFields = oldStateClone.fields.map(field => ({
				...field,
				isBeingEdited: false,
			}));
			oldStateClone.title = '';
			return {
				...oldStateClone,
				fields: [
					...newFields,
					{
						id: v4(),
						backendId: '',
						isBeingEdited: true,
						type: '' as 'Formulario',
						isValid: false,
						data: {
							id: '', // TODO: get the ID of the text component
							defaultValue: '',
							label: '',
							isRequired: true,
						},
					},
				],
			};
		}
		case 'cancel': {
			const foundIndex = oldState.fields.findIndex(item => item.id === action.payload.id);
			if (foundIndex === -1) return oldState;
			const newState = structuredClone(oldState);
			newState.title = '';
			newState.fields[foundIndex].type = '' as 'Formulario';
			newState.fields[foundIndex].data = {
				id: '',
				label: '',
				defaultValue: '',
				isRequired: true,
			};
			return newState;
		}
		case 'undo': {
			const { oldTitle } = action.payload;
			const newState = structuredClone(oldState);
			newState.title = oldTitle;
			return newState;
		}
		case 'replace': {
			return {
				...oldState,
				fields: action.payload,
			};
		}
		case 'edit': {
			const oldStateClone = structuredClone(oldState);
			const newFields = oldStateClone.fields.map(field => ({
				...field,
				isBeingEdited: false,
			}));

			const clickedItem = newFields.find(field => field.id === action.payload);
			if (!clickedItem) return oldStateClone;
			clickedItem.isBeingEdited = true;
			oldStateClone.title = clickedItem.data.label;

			return {
				...oldStateClone,
				fields: newFields,
			};
		}
		case 'delete': {
			if (oldState.fields.length === 1) return oldState;
			const oldStateClone = structuredClone(oldState);
			const newFields = oldStateClone.fields
				.filter(field => field.id !== action.payload)
				.map(field => ({ ...field, isBeingEdited: false }));

			const isEditingLastItem = oldStateClone.fields.at(-1)?.isBeingEdited;
			if (isEditingLastItem) {
				const newLastElement = newFields.at(-1);
				if (!newLastElement) return oldStateClone;
				newLastElement.isBeingEdited = true;
				oldStateClone.title = newLastElement.data.label;
			} else {
				const editingFieldIndex = oldStateClone.fields.findIndex(field => field.isBeingEdited);
				if (editingFieldIndex === -1) return oldStateClone;
				newFields[editingFieldIndex].isBeingEdited = true;
			}
			return {
				...oldStateClone,
				fields: newFields,
			};
		}
		case 'changeType': {
			const newState = structuredClone(oldState);
			const { fieldId, newType } = action.payload;
			const foundField = newState.fields.find(field => field.id === fieldId);
			if (!foundField) return oldState;
			foundField.type = newType;
			newState.title = '';
			return newState;
		}
		case 'saveFormData': {
			const newState = structuredClone(oldState);
			const { fieldId, formData } = action.payload;
			const foundField = newState.fields.find(field => field.id === fieldId);
			if (!foundField) return oldState;
			foundField.data = formData;
			foundField.isValid = true;
			return newState;
		}
		default:
			return oldState;
	}
}

interface IUseDraggableFormProps {
	initialState?: IDraggableFormState;
}

export const useDraggableForm = ({ initialState }: IUseDraggableFormProps) => {
	const [modalState, dispatch] = useReducer(
		reducer,
		initialState?.fields.length ? structuredClone(initialState) : structuredClone(INITIAL_STATE)
	);
	const { setShowError, canSubmit, setCanSubmit } = useFormModalContext();
	const fieldsContainerElementRef = useRef<HTMLDivElement>(null);

	function showErrorForSeconds(value: boolean, seconds: number = 3) {
		setShowError(value);
		setTimeout(() => setShowError(false), seconds * 1000);
	}

	// WATCH OUT! This assumes there is always a item being edited.
	const editingField = modalState.fields.find(item => item.isBeingEdited)!;

	function handleAddItem() {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'add' });

		if (!fieldsContainerElementRef.current) return;
		const fieldsContainer = fieldsContainerElementRef.current;
		// Timeout to make sure the DOM has been updated
		setTimeout(() =>
			fieldsContainer.scrollTo({
				top: fieldsContainer.scrollHeight,
				behavior: 'smooth',
			})
		);
	}

	function handleCancel() {
		dispatch({ type: 'cancel', payload: { id: editingField.id } });
		setCanSubmit(false);
	}

	function handleUndo(oldTitle: string) {
		dispatch({ type: 'undo', payload: { oldTitle } });
		setCanSubmit(false);
	}

	function handleEnableItemEdition(id: string) {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'edit', payload: id });
	}

	function handleSetItems(
		stateUpdaterFn: (oldState: Omit<IItem, 'label'>[]) => {
			oldIndex: number;
			newIndex: number;
		}
	) {
		const { oldIndex, newIndex } = stateUpdaterFn(modalState.fields);
		const swappedArray = arrayMove(modalState.fields, oldIndex, newIndex);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleChangeFieldType(newType: string) {
		dispatch({
			type: 'changeType',
			payload: { fieldId: editingField.id, newType },
		});
	}

	function handleMoveUp(index: number) {
		const swappedArray = arrayMove(modalState.fields, index, index - 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleMoveDown(index: number) {
		const swappedArray = arrayMove(modalState.fields, index, index + 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleDeleteField(id: string) {
		dispatch({ type: 'delete', payload: id });
	}

	function handleSaveItem({ title, formValues }: { title: string; formValues: IFieldValues }) {
		dispatch({
			type: 'saveFormData',
			payload: {
				fieldId: editingField.id,
				formData: {
					...formValues,
					// El title sale del estado local porque es compartido
					label: title,
				},
			},
		});
	}

	function handleReset() {
		dispatch({ type: 'reset' });
	}

	function handleChangeTitle(newTitle: string) {
		dispatch({ type: 'changeTitle', payload: newTitle });
	}

	const areFieldsDirty = modalState.fields.some((field, index) => {
		if (!initialState) return true;
		const hasChangedOrder = initialState.fields.at(index)?.id !== field.id;
		if (hasChangedOrder) return true;
		const fromProps = initialState.fields.find(initialField => initialField.id === field.id);
		if (!fromProps) return true;
		if (
			fromProps.data.label !== field.data.label ||
			fromProps.data.isRequired !== field.data.isRequired ||
			fromProps.data.id !== field.data.id ||
			(fromProps.data.defaultValue ?? '') !== (field.data.defaultValue ?? '')
		) {
			return true;
		}
		return false;
	});

	return {
		...modalState,
		editingField,
		areFieldsDirty,
		fieldsContainerElementRef,
		handlers: {
			handleAddItem,
			handleCancel,
			handleUndo,
			handleChangeFieldType,
			handleDeleteField,
			handleEnableItemEdition,
			handleMoveDown,
			handleMoveUp,
			handleSaveItem,
			handleSetItems,
			handleReset,
			handleChangeTitle,
		},
	};
};
