import { ITabRoute } from '@interfaces';
import { EditClientPage, DriversPage, NewClientPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';
import { TabbedLayout } from '@layout';

const logisticsSubRoutes: ITabRoute[] = [
	// createProtectedTabRoute({
	// 	path: 'clients',
	// 	tabName: 'Clientes',
	// 	requiredPermission: 'ENTITIES',
	// 	element: <ClientsPage />,
	// }),
	createProtectedTabRoute({
		path: 'drivers',
		tabName: 'Choferes',
		requiredPermission: 'ENTITIES',
		element: <DriversPage />,
	}),
];

export const logisticsRoutes: RouteObject[] = [
	{
		path: 'logistics',
		element: <TabbedLayout tabRoutes={logisticsSubRoutes} />,
		children: logisticsSubRoutes,
	},
	{
		path: 'logistics/new',
		element: <NewClientPage />,
	},
	{
		path: 'logistics/:id/edit',
		element: <EditClientPage />,
	},
];
