import { Controller, useForm } from 'react-hook-form';

import { FormInput, FormSearchableDropdown } from '@molecules';
import { Button, IcPistolScan } from '@atoms';

import { Modal } from '../Modal';

import type { IReturnItemFormValues, IModalReturnItemProps } from './ModalReturnItem.interfaces';

const REASONS_OPTIONS = [
	{ id: 'DAMAGED', value: 'Dañado' },
	{ id: 'WRONG_ITEM', value: 'Item incorrecto' },
];

export const ModalReturnItem = ({ isVisible, onCloseModal, itemType, selectedItem }: IModalReturnItemProps) => {
	const { register, handleSubmit: handleSubmitWrapper, control } = useForm<IReturnItemFormValues>();

	const handleSubmit = () => {
		console.log('Submitted');
	};

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Reemplazar Producto'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-3/4'>Sustituye un producto del Kit actual por otro del inventario.</p>
			<form className='mt-8 flex flex-col gap-8' onSubmit={handleSubmitWrapper(handleSubmit)} autoComplete='off'>
				<div>
					<Controller
						control={control}
						name='reasonId'
						defaultValue='DAMAGED'
						rules={{
							required: { value: true, message: 'This field is required' },
						}}
						render={({ field: { ref, ...rest }, fieldState: { error } }) => (
							<FormSearchableDropdown
								includeEmptyOption={true}
								label='Razón'
								required={true}
								options={REASONS_OPTIONS}
								onError={!!error?.message}
								errorMessage={error?.message}
								{...rest}
							/>
						)}
					/>
				</div>

				<div>
					<h2 className='mb-4 font-semibold text-bummock-ocean_blue'>Producto a ser reemplazado</h2>
					<FormInput disabled={true} label={itemType} value={selectedItem} />
				</div>

				<div>
					<h2 className='mb-4 font-semibold text-bummock-ocean_blue'>Producto de reemplazo</h2>
					<FormInput
						required={true}
						label={itemType}
						placeholder='000000'
						iconPlacement='right'
						register={register('replacement', {
							required: { value: true, message: 'This field is required' },
						})}
						icon={<IcPistolScan className='stroke-2 w-[17px] stroke-bummock-disabled_grey_2' />}
					/>
				</div>

				<div className='flex gap-4'>
					<Button onClick={onCloseModal} className='px-9' variant='secondary'>
						Cancelar
					</Button>
					<Button className='px-9'>Guardar</Button>
				</div>
			</form>
		</Modal>
	);
};
