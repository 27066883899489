import { Fragment } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { Button, DashedContainer, IcAdd } from '@atoms';
import { DeleteButton, DraggableItem, DraggableList, FormInput, FormSearchableDropdown, MoveButton } from '@molecules';
import { FormInputCheckbox } from '@molecules';
import { PROCESSES_STEP_TYPES, PROCESSES_STEP_TYPES_WITH_ID } from '@utils';
import { useFormsSelectData } from '@hooks';
import { FormWrapper } from '@organisms';

import { useDraggableForm } from '../Forms/useDraggableForm';
import { Field, IFieldValues, IDraggableFormState } from '../Forms/interfaces';

interface IFormImageFormValues {
	defaultImage: string;
	isRequired: boolean;
}

interface IFormImageFormProps {
	id: string;
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

function FormImage({ id, defaultValues, onSave, onUndo, title }: IFormImageFormProps & { title: string }) {
	const form = useForm<IFormImageFormValues>({
		defaultValues: { ...defaultValues, defaultImage: defaultValues.defaultValue },
	});

	function handleSubmit(formValues: IFormImageFormValues) {
		console.log('form submitted with values', formValues);
		onSave({ id, isRequired: formValues.isRequired, defaultValue: formValues.defaultImage, label: '' });
		form.reset({
			defaultImage: formValues.defaultImage,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<div className='flex flex-col items-start gap-4'>
				{form.watch('defaultImage') && (
					<img className='w-[8.4rem] block rounded-2xl' src={form.watch('defaultImage')} />
				)}
				<input hidden type='text' placeholder='Imagen' {...form.register('defaultImage')} />
				<input
					hidden
					id='image-input'
					type='file'
					onChange={event => {
						const file = event.target.files?.[0];
						if (!file) return;
						const reader = new FileReader();

						reader.addEventListener(
							'load',
							() => {
								form.setValue('defaultImage', reader.result as string, { shouldDirty: true });
							},
							false
						);

						reader.readAsDataURL(file);
					}}
				/>
				<label className='hover:cursor-pointer' htmlFor='image-input'>
					<Button className='h-[2.5rem] pointer-events-none'>Cargar Imagen</Button>
				</label>
			</div>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}

interface IFormSelectorFormValues {
	formId: string;
	isRequired: boolean;
}

interface IFormSelectorFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

function FormSelectorForm({ defaultValues, onSave, onUndo, title }: IFormSelectorFormProps & { title: string }) {
	const { forms } = useFormsSelectData();
	const form = useForm<IFormSelectorFormValues>({
		defaultValues: { ...defaultValues, formId: defaultValues.id },
	});

	function handleSubmit(formValues: IFormSelectorFormValues) {
		console.log('form submitted with values', formValues);
		onSave({
			id: formValues.formId,
			isRequired: formValues.isRequired,
			label: '',
			defaultValue: '',
		});
		form.reset({
			formId: formValues.formId,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<Controller
				control={form.control}
				name='formId'
				defaultValue=''
				rules={{ required: { value: true, message: 'Selecciona una lista' } }}
				render={({ field }) => (
					<FormSearchableDropdown
						required
						errorMessage={form.formState.errors.formId?.message}
						onError={!!form.formState.errors.formId}
						label='Formularios'
						options={[
							{
								id: '',
								value: 'Seleccionar formulario',
							},
							...forms,
						]}
						{...field}
					/>
				)}
			/>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}

interface ITitleFieldFormValues {
	title: string;
	isRequired: boolean;
}

interface ITitleFieldFormProps {
	id: string;
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

// I think title is unnecessary here
export function TitleForm({ id, defaultValues, onSave, onUndo, title }: ITitleFieldFormProps & { title: string }) {
	const form = useForm<ITitleFieldFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: ITitleFieldFormValues) {
		console.log('form submitted with values', formValues);
		onSave({ id, isRequired: formValues.isRequired, label: formValues.title, defaultValue: '' });
		form.reset({
			title,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<FormInputCheckbox label='Este paso es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}

interface IInnerFormProps {
	onClose: () => void;
	initialFormTitle?: string;
	initialState?: IDraggableFormState;
	onSave: (
		formData: {
			title: string;
			items: {
				fieldId?: string;
				id: string; // this is the component id
				isRequired: boolean;
				defaultValue: string;
				label: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) => void;
	canSaveFn?: (params: {
		title: {
			value: string;
			isDirty: boolean;
		};
		fields: {
			value: Field[];
			isDirty: boolean;
		};
	}) => boolean;
	isLoading: boolean;
}

export function InnerForm({
	isLoading,
	initialFormTitle = '',
	canSaveFn = () => true,
	initialState,
	onSave,
}: IInnerFormProps) {
	const form = useForm<{ formTitle: string }>({
		defaultValues: { formTitle: initialFormTitle },
	});

	const { title, fields, editingField, areFieldsDirty, handlers, fieldsContainerElementRef } = useDraggableForm({
		initialState,
	});

	const {
		handleAddItem,
		handleUndo,
		handleChangeFieldType,
		handleDeleteField,
		handleEnableItemEdition,
		handleMoveDown,
		handleMoveUp,
		handleSaveItem,
		handleSetItems,
		handleReset,
		handleChangeTitle,
	} = handlers;

	function handleSaveForm(formValues: { formTitle: string }) {
		console.log('submitted with', formValues);
		onSave(
			{
				title: formValues.formTitle,
				items: fields.map(({ data: field, backendId }, index) => ({
					fieldId: backendId,
					id: field.id,
					isRequired: field.isRequired,
					defaultValue: field.defaultValue,
					label: field.label,
					order: index.toString(),
				})),
			},
			handleReset
		);
	}

	function renderForm(fieldType: (typeof PROCESSES_STEP_TYPES)[number]) {
		const props = {
			title,
			defaultValues: editingField.data,
			onSave: (formValues: IFieldValues) =>
				handleSaveItem({
					title,
					formValues,
				}),
			onUndo: handleUndo,
		};

		switch (fieldType) {
			case 'Formulario':
				return <FormSelectorForm {...props} />;
			case 'Canvas Drawing Tool':
				return <FormImage id={PROCESSES_STEP_TYPES_WITH_ID[fieldType]} {...props} />;
			default:
				return <TitleForm id={PROCESSES_STEP_TYPES_WITH_ID[fieldType]} {...props} />;
		}
	}

	const canSave = canSaveFn({
		title: {
			value: '',
			isDirty: form.formState.isDirty,
		},
		fields: {
			value: fields,
			isDirty: areFieldsDirty,
		},
	});

	return (
		<Fragment>
			<p className='text-xs'>
				Personaliza un nuevo flujo de trabajo definiendo los pasos, valores predeterminados y opciones de
				visualización. Todos los cambios se pueden modificar más adelante.
			</p>

			<FormInput
				required
				errorMessage={form.formState.errors.formTitle?.message}
				onError={!!form.formState.errors.formTitle}
				register={form.register('formTitle', {
					required: { message: 'Ingresa un título', value: true },
				})}
				label='Título'
				placeholder='Título de Flujo de Trabajo'
			/>

			<hr />

			<div className='flex gap-4'>
				<div className='basis-0 grow'>
					<h2 className='text-xs font-semibold text-bummock-midnight_blue mb-2'>Pasos del flujo</h2>
					<div className='flex flex-col gap-4 h-[465px]'>
						<div
							ref={fieldsContainerElementRef}
							className='flex flex-col gap-4 overflow-x-hidden overflow-scroll pr-4'
						>
							<DraggableList items={fields} setItems={handleSetItems}>
								{fields.map((item, index) => (
									<DraggableItem
										key={item.id}
										id={item.id}
										label={editingField.id === item.id ? title : item.data.label}
										isBeingEdited={item.isBeingEdited}
										onEdit={() => handleEnableItemEdition(item.id)}
									>
										{({ isBeingEdited, isDragging, label }) => (
											<>
												{label === 'Separador' ? (
													<div
														className={`h-[1px] flex-grow ${isBeingEdited ? 'bg-white' : 'bg-black'}`}
													></div>
												) : (
													<span
														className={`select-none cursor-text basis-[17.75rem] grow-[2] text-sm shrink-0 h-9 rounded-lg flex items-center p-4 ${!isBeingEdited ? 'bg-bummock-off_white shadow-bummock' : ''} ${isBeingEdited ? 'text-white' : label ? 'text-black' : 'text-bummock-disabled_grey_text'}`}
													>
														{label || 'Ejemplo: Nombre del cliente'}
													</span>
												)}
												<MoveButton
													variant={isBeingEdited ? 'dark' : 'light'}
													disabled={index === 0}
													onClick={() => handleMoveUp(index)}
													isBeingEdited={isBeingEdited}
													direction='up'
												/>
												<MoveButton
													variant={isBeingEdited ? 'dark' : 'light'}
													disabled={index >= fields.length - 1}
													onClick={() => handleMoveDown(index)}
													isBeingEdited={isBeingEdited}
													direction='down'
												/>
												<DeleteButton
													variant={isBeingEdited ? 'dark' : 'light'}
													isBeingEdited={isBeingEdited}
													isDragging={isDragging}
													onClick={() => handleDeleteField(item.id)}
													disabled={fields.length === 1}
												/>
											</>
										)}
									</DraggableItem>
								))}
							</DraggableList>
						</div>
						<DashedContainer
							onClick={handleAddItem}
							className='cursor-pointer flex shrink-0 gap-2 text-sm items-center'
						>
							<IcAdd />
							<span className='font-semibold text-bummock-midnight_blue text-xs'>Agregar otro paso</span>
						</DashedContainer>
					</div>
				</div>
				<div className='w-[1px] bg-bummock-disabled_grey'></div>
				<div className='basis-0 grow flex flex-col'>
					<div className='flex flex-col gap-6 grow'>
						{/* Esto va fijo */}
						{/* When this happen the type of the current field should update */}
						<FormSearchableDropdown
							label='Tipo de componente'
							options={[
								{
									id: '',
									value: 'Selecciona un tipo de componente',
								},
								...PROCESSES_STEP_TYPES.map(fieldType => ({
									id: fieldType,
									value: fieldType,
								})),
							]}
							value={editingField.type}
							onChange={(newType: unknown) =>
								handleChangeFieldType(newType as (typeof PROCESSES_STEP_TYPES)[number])
							}
						/>
						{/* This is dynamic based on the selected field type */}
						{editingField.type ? (
							<Fragment key={editingField.id}>
								<FormInput
									label='Título'
									placeholder='Título del campo'
									required
									value={title}
									onChange={e => handleChangeTitle(e!.target.value)}
								/>
								{renderForm(editingField.type as unknown as (typeof PROCESSES_STEP_TYPES)[number])}
							</Fragment>
						) : null}
					</div>
				</div>
			</div>
			<Button
				disabled={!canSave}
				isLoading={isLoading}
				onClick={form.handleSubmit(handleSaveForm)}
				className='text-sm ml-auto h-10'
			>
				Guardar Flujo de Trabajo
			</Button>
		</Fragment>
	);
}
