import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button } from '@atoms';
import { FormInput } from '@molecules';
import { createKitsUsage } from '@api';
import { useAppSelector } from '@hooks';

import type { IModalProps } from '@interfaces';

import { Modal } from '../Modal';

import type { INewKitUsageValues } from './ModalNewKitUsage.interfaces';

export const ModalNewKitUsage = ({ isVisible, onCloseModal }: IModalProps) => {
	const { id: userId, companies } = useAppSelector(state => state.userProfile);

	const { register, handleSubmit: handleSubmitWrapper, reset } = useForm<INewKitUsageValues>();

	useEffect(() => {
		isVisible && reset();
	}, [isVisible]);

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: createKitsUsage,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({
				queryKey: ['usages'],
			});
		},
	});

	const handleSubmit = ({ name, description }: INewKitUsageValues) => {
		mutation.mutate({
			company: companies[0].id,
			user: userId,
			name,
			description,
		});
	};

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Creación de Uso de Kit'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-3/4'>Agrega un uso específico para facilitar la identificación de tus kits creados.</p>
			<form className='mt-8 flex flex-col gap-8' onSubmit={handleSubmitWrapper(handleSubmit)} autoComplete='off'>
				<FormInput
					label='Nombre'
					placeholder='Kit ABC'
					required={true}
					register={register('name', {
						required: { value: true, message: 'This field is required' },
					})}
				/>
				<FormInput
					label='Descripción de uso'
					placeholder='For ABC type of container'
					required={true}
					register={register('description', {
						required: { value: true, message: 'This field is required' },
					})}
				/>
				<div className='flex gap-4'>
					<Button type='button' onClick={onCloseModal} className='px-9' variant='secondary'>
						Cancelar
					</Button>
					<Button isLoading={mutation.isPending} className='px-9'>
						Guardar
					</Button>
				</div>
			</form>
		</Modal>
	);
};
