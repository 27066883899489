import { DashedContainer, IcAdd } from '@atoms';

interface IButtonDashedProps {
	onClick: VoidFunction;
	children?: string;
}

export const ButtonDashed = ({ onClick, children = '' }: IButtonDashedProps) => {
	return (
		<DashedContainer onClick={onClick} className='cursor-pointer flex shrink-0 gap-2 text-sm items-center'>
			<IcAdd />
			<span className='font-semibold text-bummock-midnight_blue text-xs'>{children}</span>
		</DashedContainer>
	);
};
