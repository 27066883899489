import { apiPrivate } from '../config/apiPrivate';

interface ICreateWorkflowParams {
	title: string;
	items: {
		id: string;
		title: string;
		isRequired: boolean;
	}[];
}

export const createWorkflow = async (params: ICreateWorkflowParams) => {
	const { data } = await apiPrivate.post('/api/workflow/register', params);
	return data;
};
