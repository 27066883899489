export const FIELD_TYPES = [
	'Texto',
	'Número',
	'Lista desplegable',
	'Lista desplegable selección múltiple',
	'Fecha y hora',
	'Hora',
	'Título',
	'Texto informativo / instrucción',
	'Separador',
	'Foto',
	'Escáner de kit',
	'Escáner de texto',
	'Escáner de QR',
	'Formato kit',
	'Entidades',
] as const;

export const FIELD_TYPES_WITH_IDS: Record<string, (typeof FIELD_TYPES)[number]> = {
	'37b6f1ce-d029-41cf-9082-1a2bb708df7a': 'Texto',
	'8577c344-fcbd-49ee-9264-28cf9de3da05': 'Número',
	'f25884f7-9842-40f7-b1e3-8d6bcbe79171': 'Lista desplegable',
	'9088c067-f22d-49a9-b54f-1e5042128641': 'Entidades',
	'70ebc165-0e2b-47f7-8cb0-bdbe7c55a0e1': 'Lista desplegable selección múltiple',
	// Falta checkbox
	'3d316357-15b6-402e-84a7-bac65f91957f': 'Fecha y hora',
	'6167cc28-b112-4861-8191-f0ff8d596398': 'Hora',
	'b8cd4987-9c7a-4b0d-ae5f-4f2daa58c7b8': 'Título',
	'36e7a5e7-3b85-4b0d-b8a7-1dc5c08173b4': 'Texto informativo / instrucción',
	'd12c56c8-df55-4560-b060-dffda4baf904': 'Separador',
	'5e5195a2-8da4-422b-8861-ea6b612ca220': 'Foto',
	'678f3dc6-f4fc-46e2-a14d-91cd2e656da1': 'Escáner de kit',
	'ce5cbfcc-1215-4f9b-8fb4-820d6c0b2e3a': 'Escáner de texto',
	'33137d17-4776-4345-a524-325715112652': 'Escáner de QR',
	'76d41b2a-3410-48e9-8590-818e0897f7db': 'Formato kit',
};
