import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { IcBell, LinkIcon } from '@atoms';
import { useAppSelector } from '@hooks';

import type { RootState } from '@RTK/Store';

import styles from './Header.module.sass';

const WELCOME_FADE_OUT_TIME_IN_MS = 5000;

// Should be removed eventually
const HEADER_MAP = {
	users: 'Usuarios',
	company: 'Empresa',
	entities: 'Entidades',
	logistics: 'Logística',
	inventory: 'Inventario',
	kits: 'Kits',
	dispatch: 'Despacho',
	inspections: 'Inspecciones',
	profile: 'Perfil',
	processes: 'Procesos',
};

export const Header = () => {
	const { firstName, lastName, image: userImage } = useAppSelector((state: RootState) => state.userProfile);
	// TODO: Make Headers independant from path
	const { pathname } = useLocation();
	const [isWelcome, setIsWelcome] = useState<boolean>(true);

	useEffect(() => {
		const timerId = setTimeout(() => setIsWelcome(false), WELCOME_FADE_OUT_TIME_IN_MS);
		return () => clearTimeout(timerId);
	});

	return (
		<header className={styles['header']}>
			<h1 className={styles['header__message']}>
				<span className={`${styles['header__message__content']} ${isWelcome ? 'opacity-100' : 'opacity-0'}`}>
					Bienvenido, {firstName}.
				</span>
				<span className={`${styles['header__message__content']} ${isWelcome ? 'opacity-0' : 'opacity-100'}`}>
					{HEADER_MAP[pathname.split('/')[1] as keyof typeof HEADER_MAP]}
				</span>
			</h1>
			<nav className={styles['header__actions']}>
				<LinkIcon to='/notifications' className={styles['header__actions__action']} icon={<IcBell />} />
				<LinkIcon
					to='/profile/information'
					className={`${styles['header__actions__action']} ${styles['header__actions__action--image']}`}
					icon={
						userImage ? (
							<img src={userImage} />
						) : (
							<div
								className={`${styles['header__actions__action']} ${styles['header__actions__action--avatar']}`}
							>
								{firstName.charAt(0)}
								{lastName.charAt(0)}
							</div>
						)
					}
				/>
			</nav>
		</header>
	);
};
