import { apiPrivate } from '../config/apiPrivate';

interface ICreateListParams {
	title: string;
	detail: string[];
}

export const createList = async (params: ICreateListParams) => {
	const { data } = await apiPrivate.post('/api/workflow/custom-list', params);
	return data;
};
