import { apiPrivate } from '../config/apiPrivate';

interface IGetOneListParams {
	listId: string;
}

interface IGetOneListResp {
	id: string;
	title: string;
	detail: {
		id: string;
		description: string;
	}[];
}

export const getOneList = async (params: IGetOneListParams) => {
	const { data } = await apiPrivate.get<IGetOneListResp>(`/api/workflow/custom-list/${params.listId}`);
	return data;
};
