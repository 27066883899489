import { apiPrivate } from '../config/apiPrivate';

interface IDeleteFormParams {
	id: string;
}

export const deleteProcess = async (params: IDeleteFormParams) => {
	const { data } = await apiPrivate.delete(`/api/workflow/process/${params.id}`);
	return data;
};
