import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcTruck: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 15' fill='none' {...props}>
			<path
				d='M10.5 3.45642H12.2529C12.4364 3.45642 12.5281 3.45642 12.6144 3.47714C12.691 3.49552 12.7641 3.52582 12.8312 3.56694C12.9069 3.61332 12.9718 3.67818 13.1015 3.80789L16.1485 6.85495C16.2782 6.98466 16.3431 7.04952 16.3895 7.12521C16.4306 7.19231 16.4609 7.26547 16.4793 7.342C16.5 7.42831 16.5 7.52003 16.5 7.70348V9.83142C16.5 10.1809 16.5 10.3556 16.4429 10.4934C16.3668 10.6772 16.2208 10.8232 16.037 10.8993C15.8992 10.9564 15.7245 10.9564 15.375 10.9564M11.625 10.9564H10.5M10.5 10.9564V3.60642C10.5 2.76634 10.5 2.3463 10.3365 2.02544C10.1927 1.74319 9.96323 1.51372 9.68099 1.36991C9.36012 1.20642 8.94008 1.20642 8.1 1.20642H3.9C3.05992 1.20642 2.63988 1.20642 2.31901 1.36991C2.03677 1.51372 1.8073 1.74319 1.66349 2.02544C1.5 2.3463 1.5 2.76634 1.5 3.60642V9.45642C1.5 10.2848 2.17157 10.9564 3 10.9564M10.5 10.9564H7.5M7.5 10.9564C7.5 12.1991 6.49264 13.2064 5.25 13.2064C4.00736 13.2064 3 12.1991 3 10.9564M7.5 10.9564C7.5 9.71378 6.49264 8.70642 5.25 8.70642C4.00736 8.70642 3 9.71378 3 10.9564M15.375 11.3314C15.375 12.367 14.5355 13.2064 13.5 13.2064C12.4645 13.2064 11.625 12.367 11.625 11.3314C11.625 10.2959 12.4645 9.45642 13.5 9.45642C14.5355 9.45642 15.375 10.2959 15.375 11.3314Z'
				stroke='#092D45'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};
