import { apiPrivate } from '@api';

interface IGetOneWorkflowParams {
	workflowId: string;
}

interface IWorkflowStep {
	id: string;
	order: number;
	title: string;
	isRequired: boolean;
	defaultValue: string;
	isEditable: boolean;
	formHeader: {
		id: string; // This is the id I am interested in
		formType: {
			id: string;
			name: string;
		};
		formDetails: {
			id: string;
			order: number;
			label: string;
			defaultValue: string;
			isRequired: boolean;
			component: {
				id: string;
				name: string;
				identifier_code: string;
				componentType: {
					id: string;
					name: string;
				};
				dataType: string;
			};
		}[];
	};
}

interface IGetOneWorkflowResp {
	id: string;
	title: string;
	data: IWorkflowStep[];
}

function acl(formDetail: IWorkflowStep) {
	return {
		id: formDetail.id,
		// order: index, // this could be unnecessary
		label: formDetail.title,
		defaultValue: formDetail.defaultValue, // This is always empty. Request fix
		isRequired: formDetail.isRequired, // Im not getting the required either
		isActive: true,
		componentDataType: {
			id: formDetail.formHeader.id,
		},
	};
}

export const getOneWorkflow = async (params: IGetOneWorkflowParams) /*: Promise<IGetOneWorkflowResp>*/ => {
	const { data: response } = await apiPrivate.get<IGetOneWorkflowResp>(`/api/workflow/${params.workflowId}`);
	return {
		id: params.workflowId,
		name: response.title,
		details: response.data.sort((a, b) => a.order - b.order).map(acl),
	};
};
