import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal } from '../Modal';
import { InnerForm } from './InnerForm';
import { editProcess } from '@api';
import toast from 'react-hot-toast';
import styles from '../Forms/overrides.module.sass';
import { IFormData } from './interfaces';
import { FormModalProvider } from '../Forms/ctx';

interface IModalNewForm {
	initialProcessData: IFormData & { id: string };
	isModalVisible: boolean;
	onClose: () => void;
}

export const ModalEditProcess = ({ isModalVisible, initialProcessData, onClose }: IModalNewForm) => {
	const queryClient = useQueryClient();
	const createFormMutation = useMutation({
		mutationFn: editProcess,
		onSuccess: () => {
			toast.success('Proceso editado exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['processes'] });
		},
	});

	async function handleSave(formData: IFormData, onSuccess?: () => void) {
		const { headerForm, items } = formData;
		console.log('new field has', formData);
		await createFormMutation.mutateAsync({
			processId: initialProcessData.id,
			body: {
				processLocations: headerForm.locationsIds.map(locationId => ({
					companyLocation: locationId,
				})),
				referenceTitle: headerForm.identifierTitle,
				referenceType: headerForm.identifierTypeId,
				title: headerForm.formTitle,
				workflowGroup: items.map(workflow => ({
					...(workflow.fieldId ? { id: workflow.fieldId } : {}),
					workflowHeader: workflow.id || '',
				})),
			},
		});
		if (onSuccess) onSuccess();
	}
	console.log(initialProcessData);

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Editar Proceso'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					defaultFormHeaderValues={{
						...initialProcessData.headerForm,
					}}
					initialState={{
						title: '',
						fields: initialProcessData.items.map((item, index) => ({
							id: item.fieldId || '',
							backendId: item.fieldId || '',
							data: {
								id: item.id || '',
								defaultValue: '',
								isRequired: false,
								label: item.label,
							},
							isBeingEdited: index === 0,
							isValid: true,
							type: item.id || '',
						})),
					}}
					canSaveFn={({ title, fields }) =>
						title.isDirty || (fields.isDirty && fields.value.every(field => field.isValid))
					}
					key={isModalVisible + ''}
					isLoading={createFormMutation.isPending}
					onSave={handleSave}
					onClose={onClose}
				/>
			</FormModalProvider>
		</Modal>
	);
};
