import { Button } from '@atoms';

import { Modal } from '../Modal';

import type { IModalConfirmationProps } from './ModalConfirmation.interfaces';

export const ModalConfirmation = ({
	confirmationMessage,
	confirmLabel = 'Confirmar',
	rejectLabel = 'Cancelar',
	isVisible,
	onConfirm,
	onReject,
}: IModalConfirmationProps) => {
	return (
		<Modal className='p-12' isOpen={isVisible} onClose={onReject}>
			<p className='text-xl text-bummock-ocean_blue text-center font-bold mb-8'>
				{confirmationMessage ?? 'Has realizado cambios. ¿Deseas confirmarlos?'}
			</p>
			<div className='flex gap-4 justify-center'>
				<Button onClick={onReject} className='w-[150px]' variant='secondary'>
					{rejectLabel}
				</Button>
				<Button onClick={onConfirm} className='w-[150px]'>
					{confirmLabel}
				</Button>
			</div>
		</Modal>
	);
};
