export * from './constants/api';
export * from './constants/env';
export * from './constants/fields';
export * from './constants/file_formats';
export * from './constants/localStorage';
export * from './constants/mail';
export * from './constants/pattern';
export * from './constants/processes';
export * from './constants/regex';
export * from './helpers';
export { type MODULES, type TPermissions, PERMISSIONS_BY_LEVELS, ROLES } from './constants/permissions';

export const ITEMS_PER_PAGE = 8;
