import { IFieldValues } from '@interfaces';
import { Controller, useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox, FormSearchableDropdown } from '@molecules';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { findAllLists } from '@api';

interface IListFieldFormValues {
	title: string;
	listId: string;
	isRequired: boolean;
}

interface IListFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

const BACKEND_LISTS = [
	{
		id: 'd68d6a55-c7e6-4f14-88ab-2477f650dff9',
		value: 'Lista de cliente',
	},
	{
		id: '69211563-43c3-47cf-abbd-d266ab7b95f3',
		value: 'Lista de patios',
	},
	{
		id: '96f311d6-01dc-44ab-b3a8-4a099be9e4bb',
		value: 'Lista de navieras',
	},
	{
		id: '00a6fa6c-bb74-43b6-8908-a89d88a50392',
		value: 'Lista de conductores',
	},
	{
		id: '619a98ab-c415-4420-a6ac-8a50a1ee45ad',
		value: 'Lista de transportistas',
	},
];

const CUSTOM_LIST = {
	id: 'e8b9d6aa-0154-4dc5-b2a4-e9b01e66a332',
	value: 'Listas dinamicas',
};

// Si al guardar, el id de la lista no pertenece al arreglo de listas, se debe enviar
// el id de CUSTOM_LIST y como valor por defecto el de la custom list

// Al precargar, si el ID es el de custom list, se debe usar el valor por defecto como listId

function useUnpaginatedLists() {
	const { data } = useQuery({
		queryKey: ['lists-unpaginated'],
		queryFn: () =>
			findAllLists({
				page: 0,
				limit: 100000,
				sorting: [],
				searchTerm: '',
			}),
	});

	if (!data?.data) return [];

	return data.data.map(item => ({
		id: item.id,
		value: item.title,
	}));
}

const isCustomList = (list: { listId: string }) => {
	if (!list.listId) return false;
	return !BACKEND_LISTS.some(backendList => backendList.id === list.listId);
};

export function FormListField({ defaultValues, onSave, onUndo, title }: IListFieldFormProps & { title: string }) {
	console.log(defaultValues);
	const form = useForm<IListFieldFormValues>({
		defaultValues: {
			...defaultValues,
			title,
			listId: isCustomList({ listId: defaultValues.defaultValue })
				? defaultValues.defaultValue
				: defaultValues.id,
		},
	});

	const lists = useUnpaginatedLists();

	function handleSubmit(formValues: IListFieldFormValues) {
		console.log('form submitted with values', formValues);
		toast.success('Campo guardado.', { position: 'bottom-right' });

		if (isCustomList(formValues)) {
			console.log('saving custom list');
			onSave({
				id: CUSTOM_LIST.id,
				defaultValue: formValues.listId,
				isRequired: formValues.isRequired,
				label: formValues.title,
			});
		} else {
			console.log('not saving custom list');
			onSave({
				id: formValues.listId,
				defaultValue: '',
				isRequired: formValues.isRequired,
				label: formValues.title,
			});
		}

		form.reset({
			title,
			listId: formValues.listId,
			isRequired: formValues.isRequired,
		});
	}

	// Cuando se pasa una lista custom, se debe seleccionar el "custom list" y pasar por valor por defecto

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<Controller
				control={form.control}
				name='listId'
				defaultValue=''
				rules={{ required: { value: true, message: 'Selecciona una lista' } }}
				render={({ field }) => (
					<FormSearchableDropdown
						required
						errorMessage={form.formState.errors.listId?.message}
						onError={!!form.formState.errors.listId}
						label='Listas'
						options={[
							{
								id: '',
								value: 'Seleccionar lista',
							},
							...BACKEND_LISTS,
							...lists,
						]}
						{...field}
					/>
				)}
			/>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
