import { Button, IcPlus, IcTrash, Badge, IcEdit, IcDeny } from '@atoms';
import { BaseTable, ModalInviteEntity, ModalNewEntity, TablePagination, TableSearchbar } from '@organisms';
import { findAllEntities, QUERY_KEYS } from '@api';
import { useTableQuery } from '@hooks';
import { IEntity } from '@interfaces';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const columnHelper = createColumnHelper<IEntity>();
const shippingCompaniesColumns: ColumnDef<IEntity, string>[] = [
	columnHelper.accessor('name', {
		header: 'Entidad',
	}),
	columnHelper.accessor('country', {
		header: 'País',
	}),
	columnHelper.accessor('city', {
		header: 'Ciudad',
	}),
	columnHelper.accessor('category', {
		header: 'Categoría',
	}),
	columnHelper.accessor('creationDate', {
		header: 'Fecha de Creación',
	}),
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue().toUpperCase() === 'APPROVED' ? 'Aprobado' : 'Pendiente'}
				variant={columnDefBase.getValue().toUpperCase() === 'APPROVED' ? 'success' : 'warning'}
			/>
		),
		meta: { alignment: 'left' },
		enableSorting: false,
	}),
];

const ShippingCompaniesPage = () => {
	const navigator = useNavigate();

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.entities],
		fetcherFn: findAllEntities,
		tableColumns: [
			...shippingCompaniesColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { status, id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcTrash
								onClick={() => console.log('Clicked item with id', id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcDeny
								onClick={() => console.log('Disabling item with ID', id)}
								className={`${status.toUpperCase() === 'DESACTIVADO' ? ' fill-red-500' : 'hover:fill-bummock-midnight_blue'} w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2`}
							/>
							<IcEdit
								onClick={() => navigator(`/entities/${id}/edit`)}
								className='w-5 cursor-pointer transition-all ease-in-out duration-200 stroke-2 stroke-bummock-disabled_grey_2 hover:stroke-bummock-midnight_blue'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

	return (
		<>
			<ModalNewEntity
				isVisible={isCreationModalOpen}
				onCloseModal={() => setIsCreationModalOpen(false)}
				onClickInvite={() => {
					setIsCreationModalOpen(false);
					setIsInviteModalOpen(true);
				}}
			/>
			<ModalInviteEntity isVisible={isInviteModalOpen} onCloseModal={() => setIsInviteModalOpen(false)} />

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => setIsCreationModalOpen(true)}>
						<IcPlus className='stroke-2 stroke-white w-4' /> Agregar Entidad
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default ShippingCompaniesPage;
