import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal } from '../Modal';
import { InnerForm } from './InnerForm';
import { createForm } from '@api';
import toast from 'react-hot-toast';
import { IFormData } from './interfaces';
import styles from './overrides.module.sass';
import { FormModalProvider } from './ctx';

interface IModalNewForm {
	isModalVisible: boolean;
	onClose: () => void;
}

export const ModalNewForm = ({ isModalVisible, onClose }: IModalNewForm) => {
	const queryClient = useQueryClient();
	const createFormMutation = useMutation({
		mutationFn: createForm,
		onSuccess: () => {
			toast.success('Formulario creado exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['forms'] });
		},
	});

	async function handleSave(formData: IFormData, onSuccess?: () => void) {
		await createFormMutation.mutateAsync({
			title: formData.title,
			items: formData.items.map(field => ({
				id: field.id,
				isRequired: field.isRequired,
				defaultValue: field.defaultValue || '',
				label: field.label,
			})),
		});
		if (onSuccess) onSuccess();
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Crear Formulario'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					canSaveFn={({ fields }) =>
						// Every field is filled
						fields.value.filter(item => item.isValid).length === fields.value.length
					}
					key={isModalVisible + ''}
					isLoading={createFormMutation.isPending}
					onSave={handleSave}
					onClose={onClose}
				/>
			</FormModalProvider>
		</Modal>
	);
};
