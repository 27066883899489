import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FormWrapper } from './FormWrapper';
import { FormInput, FormInputCheckbox } from '@molecules';

interface ITextFieldFormValues {
	title: string;
	defaultValue: string;
	isRequired: boolean;
}

interface ITextFieldFormProps {
	defaultValues: ITextFieldFormValues;
	onSave: (fieldValues: IFieldValues) => void;
	onUndo: (oldTitle: string) => void;
}

const id = '37b6f1ce-d029-41cf-9082-1a2bb708df7a';
export function FormTextField({ defaultValues, onSave, onUndo, title }: ITextFieldFormProps & { title: string }) {
	const form = useForm<ITextFieldFormValues>({
		defaultValues,
	});

	function handleSubmit(formValues: ITextFieldFormValues) {
		console.log('form submitted with values', formValues);
		toast.success('Campo guardado.', { position: 'bottom-right' });
		onSave({
			id,
			label: title,
			defaultValue: formValues.defaultValue,
			isRequired: formValues.isRequired,
		});

		form.reset({
			title,
			defaultValue: formValues.defaultValue,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onUndo={onUndo}>
			<FormInput
				// className='h-[7.5rem]'
				label='Valor predeterminado'
				placeholder='Escribe un valor que aparecerá por defecto'
				register={form.register('defaultValue')}
			/>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
