import { getPaginatedResource } from '@api';
import { IReferenceType } from '@interfaces';

export const findAllReferenceTypes = async (): Promise<IReferenceType[]> => {
	const { data } = await getPaginatedResource<IReferenceType>('/api/workflow/reference-type', {
		page: 0,
		limit: 1000000,
		sorting: [],
		searchTerm: '',
	});
	return data.data;
};
