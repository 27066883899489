import { apiPrivate } from '../config/apiPrivate';

interface IEditWorkflowParams {
	workflowId: string;
	title: string;
	items: {
		id?: string; // if this is empty, a new item will be created
		formHeader: string;
		title: string;
		isRequired: boolean;
		defaultValue?: string;
	}[];
}

export const editWorkflow = async (params: IEditWorkflowParams) => {
	const { data } = await apiPrivate.patch(`/api/workflow/edit/${params.workflowId}`, params);
	return data;
};

// Missing chars
// `
