import { Button, ButtonGoBack, Switch } from '@atoms';
import { FormInput, FormInputPhone, FormSearchableDropdown } from '@molecules';
import { ImagePicker } from '@organisms';

const EditShippingCompanyPage = () => {
	return (
		<>
			<ButtonGoBack />

			<header className='flex flex-col gap-2'>
				<h2 className='text-xl font-bold text-bummock-ocean_blue'>Detalles de la Entidad</h2>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
			</header>

			{/* Todo esto es un solo form */}
			<div className='flex items-end justify-between'>
				<ImagePicker placeHolderImage='/company-profile-image.png' />
				<FormSearchableDropdown label='Categoría' value='' options={[{ id: '', value: 'Naviera' }]} />
			</div>

			<hr />

			<div className='grid grid-cols-4 gap-4'>
				<FormInput placeholder='-' label='Empresa' />
				<FormInput placeholder='-' label='RUC' />
				<FormInput placeholder='-' label='Correo Electrónico del Administrador' />
				<FormInputPhone placeholder='-' label='Número de Contacto' />

				<FormInput placeholder='-' label='Nombre del Administrador' />
				<FormInput placeholder='-' label='Apellido del Administrador' />
				<FormInput placeholder='-' label='Cédula' />
				<FormSearchableDropdown label='País' value='' options={[{ id: '', value: 'Ecuador' }]} />

				<FormSearchableDropdown label='Provincia' value='' options={[{ id: '', value: 'Guayas' }]} />
				<FormSearchableDropdown label='Ciudad' value='' options={[{ id: '', value: 'Guayaquil' }]} />
			</div>

			<hr />

			<div className='shadow-bummock rounded-2xl p-8 flex items-center gap-[8rem] justify-between'>
				<div>
					<header className='text-xl font-bold text-bummock-ocean_blue mb-4'>Compartir Informacion</header>
					<p className='text-bummock-ocean_blue'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
						laboris nisi ut aliquip ex ea commodo consequat.
					</p>
				</div>
				<Switch id='' name='' />
			</div>

			<div className='shadow-bummock rounded-2xl p-8 flex items-center gap-[8rem] justify-between'>
				<div>
					<header className='text-xl font-bold text-bummock-ocean_blue mb-4'>
						Compartir Informacion de terceros
					</header>
					<p className='text-bummock-ocean_blue'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
						laboris nisi ut aliquip ex ea commodo consequat.
					</p>
				</div>
				<Switch id='' name='' />
			</div>

			<div className='shadow-bummock rounded-2xl p-8 flex flex-col items-start gap-4'>
				<header className='text-xl font-bold text-bummock-ocean_blue'>
					Compartir Informacion de localidades especificas
				</header>
				<FormSearchableDropdown
					label='Compartir Localidades'
					value=''
					options={[{ id: '', value: 'Guayaquil' }]}
				/>
			</div>

			<Button>Guardar Cambios</Button>
		</>
	);
};

export default EditShippingCompanyPage;
