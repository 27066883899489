import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal } from '../Modal';
import { updateList } from '@api';
import toast from 'react-hot-toast';
import styles from '../Forms/overrides.module.sass';
import { FormModalProvider } from '../Forms/ctx';
import { InnerForm } from './InnerForm';

interface IModalEditList {
	initialListData: {
		id: string;
		title: string;
		detail: {
			id: string;
			description: string;
		}[];
	};
	isModalVisible: boolean;
	onClose: VoidFunction;
}

export const ModalEditList = ({ isModalVisible, onClose, initialListData }: IModalEditList) => {
	const queryClient = useQueryClient();
	const createFormMutation = useMutation({
		mutationFn: updateList,
		onSuccess: () => {
			toast.success('Lista editada exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['lists'] });
		},
	});

	async function handleSave(
		formData: {
			title: string;
			items: {
				id: string;
				backendId?: string;
				value: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) {
		await createFormMutation.mutateAsync({
			id: initialListData.id,
			title: formData.title,
			itemsDetail: formData.items.map(field => ({
				...(field.backendId ? { id: field.backendId } : {}),
				detail: field.value,
			})),
		});
		if (onSuccess) onSuccess();
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Editar Lista'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					canSaveFn={({ title, fields }) => title.isDirty || fields.isDirty}
					initialFormTitle={initialListData.title}
					initialState={initialListData.detail.map(detail => ({
						id: detail.id,
						data: { id: detail.id, value: detail.description },
						isBeingEdited: false,
						isValid: false,
					}))}
					key={isModalVisible + ''}
					isLoading={createFormMutation.isPending}
					onSave={handleSave}
					onClose={onClose}
				/>
			</FormModalProvider>
		</Modal>
	);
};
