import { apiPrivate } from '../config/apiPrivate';

interface ICreateFormParams {
	title: string;
	items: {
		id: string;
		label: string;
		isRequired: boolean;
		defaultValue: string;
	}[];
}

export const createForm = async (params: ICreateFormParams) => {
	const { data } = await apiPrivate.post('/api/workflow/form', params);
	return data;
};
