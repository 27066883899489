import { IModalProps } from '@interfaces';
import { Modal } from '../Modal';
import { FormInput, FormSearchableDropdown } from '@molecules';
import { Button } from '@atoms';

export const ModalInviteEntity = ({ isVisible, onCloseModal }: IModalProps) => {
	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Agregar Entidad'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<div className='flex flex-col gap-4'>
				<p className='text-2xs w-3/4'>
					Start with the companies ID number in order to see if they are already using Bummock. If not then
					proceed to complete the required fields.
				</p>

				<div className='flex gap-4'>
					<FormInput label='Empresa' required placeholder='-' className='basis-0 grow' />
					<FormInput label='RUC' required placeholder='-' className='basis-0 grow' />
					<FormInput
						label='Correo Electrónico del Administrador'
						required
						placeholder='-'
						className='basis-0 grow'
					/>
				</div>

				<div className='flex gap-4'>
					<FormSearchableDropdown required className='basis-0 grow' label='Categoría' options={[]} value='' />
					<FormSearchableDropdown
						disabled
						className='basis-0 grow'
						label='Compartir Localidades'
						options={[]}
						value=''
					/>
				</div>

				<hr />

				<p className='text-2xs text-bummock-disabled_grey_2'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
					et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat.
				</p>

				<Button className='self-end'>Agregar Entidad</Button>
			</div>
		</Modal>
	);
};
