import { RouteObject } from 'react-router-dom';
import { companiesRoutes } from './companies';
import { companyRoutes } from './company';
import { usersRoutes } from './users';
import { entitiesRoutes } from './entities';
import { sealsRoutes } from './seals';
import { inventoryRoutes } from './inventory';
import { kitsRoutes } from './kits';
import { inspectionsRoutes } from './inspections';
import { dispatchRoutes } from './dispatch';
import { profileRoutes } from './profile';
import { processesRoutes } from './processes';
import { logisticsRoutes } from './logistics';

export const DashboardRoutes: RouteObject[] = [
	...companiesRoutes,
	...companyRoutes,
	...usersRoutes,
	...entitiesRoutes,
	...logisticsRoutes,
	...sealsRoutes,
	...inventoryRoutes,
	...kitsRoutes,
	...inspectionsRoutes,
	...dispatchRoutes,
	...profileRoutes,
	...processesRoutes,
];
